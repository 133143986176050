import React, {useEffect, useState} from 'react';

//Affichage du détails d'une commande
function OrderModal(props) {

    const order = props.order.order;
    const allItems = order.items;
    const [allItemsList, setAllItemsList] = useState([]);

    useEffect(() => {

        const tab = []

        for (const item in allItems) {
            tab.push(allItems[item]);
        }

        setAllItemsList(tab);

    }, [allItems])


    if(!props.show) {
        return null
    }

    return (

        <div id="modal-overlay" onClick={props.isShowing} className="fixed inset-0 overflow-hidden bg-gray-400 bg-opacity-60 w-screen h-screen flex flex-col items-center justify-center">
            <div onClick={event => event.stopPropagation()} className="my-10 py-3 bg-white rounded-lg overflow-auto w-5/6 lg:w-1/2 flex flex-col">
                <div id="modal-header" className="self-end mx-3">
                    <button type="button" className="cursor-pointer text-3xl font-semibold" onClick={props.isShowing}>&times;</button>
                </div>
                <div id="modal-body" className="flex flex-col text-xs md:text-base mt-3 mx-2">
                    <div className="self-center">
                        Commande passée le : {props.date}
                    </div>
                    <ul className="flex flex-col my-6">
                        <li className="flex font-bold text-xs md:text-base text-center mb-1 break-words">
                            <div className="w-1/5 text-left md:text-center">Désignation</div>
                            <div className="w-1/5">Description</div>
                            <div className="w-1/5">Quantité</div>
                            <div className="w-1/5">Prix</div>
                            <div className="w-1/5">Total</div>
                        </li>
                        <hr/>
                        {allItemsList.map(item => <><li className="flex items-center break-words text-xs md:text-base mb-1 text-center my-1" key={item.index}>
                            <div className="w-1/5 text-left md:text-center">{item.item.name}</div>
                            <div className="w-1/5">{item.item.description}</div>
                            <div className="w-1/5">{item.qty}</div>
                            <div className="w-1/5">{item.item.price}</div>
                            <div className="w-1/5">{item.qty * item.item.price}</div>
                        </li><hr/></>)}
                    </ul>
                    <div className="self-end mr-2">
                        TOTAL : {order.totalprice}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrderModal;