import React from 'react';

//Composant pour l'ensemble des gros titres "Pharmacie Brisson - XXXXX"
function Title(props) {

    return (
            <div className="flex flex-col items-center my-10">
                {/* Visible sur mobile */}
                <div className="flex flex-col items-center self-center text-xl mt-3 lg:hidden text-green-one text-center">
                    <p>Pharmacie Brisson</p>
                    <p className="mb-2">{props.title}</p>
                    <hr className="w-full self-center border-1 border-purple-one bg-purple-one w-1/2 text-center lg:hidden mb-10"/>
                </div>

                {/* Visible sur desktop */}
                <div className="flex flex-col items-center">
                    <h2 className="hidden self-center lg:inline mb-2 text-3xl text-green-one text-center">Pharmacie Brisson - {props.title}</h2>
                    <hr className="w-full hidden lg:inline self-center border-1 border-gray-400 bg-gray-400 text-center"/>
                </div>
            </div>
    );
}

export default Title;