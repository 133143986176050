import React, {useContext, useEffect, useState} from 'react';

import AuthContext from "../../contexts/AuthContext";
import FirebaseContext from "../../contexts/FirebaseContext";

import OrderList from "./OrderList";
import PrescriptionList from "./PrescriptionList";
import Avatar from "../Avatar";
import UserInfos from "./UserInfos";

//Page : mon-compte
function MyAccount() {

    const userConnected = useContext(AuthContext);
    const firebase = useContext(FirebaseContext);

    const [order, setOrder] = useState(true);
    const [prescription, setPrescription] = useState(false);
    const [userInfos, setUserInfos] = useState(false);

    //Récupération des ordonnances et commandes de l'user
    const [prescriptions, setPrescriptions] = useState([]);
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        const allPrescriptions = [];

        if(userConnected) {
            firebase.getPrescriptions(userConnected.uid)
                .then(query => {
                    query.forEach(
                        (doc) => {
                            let datas = doc.data();
                            datas.id = doc.id;
                            allPrescriptions.push(datas);
                        });
                    setPrescriptions(allPrescriptions);
                })
                .catch(error => {
                    console.log(error.message);
                })
        }
    }, [firebase, userConnected]);

    useEffect(() => {
        const allOrders = [];

        if(userConnected) {
            firebase.getOrders(userConnected.uid)
                .then(query => {
                    query.forEach(
                        (doc) => {
                            let datas = doc.data();
                            datas.id = doc.id ;
                            allOrders.push(datas);
                        });
                    setOrders(allOrders);
                })
                .catch(error => {
                    console.log(error.message);
                })
        }
    }, [firebase, userConnected]);


    //Affichage des commandes
    const handleOrder = () => {
        setOrder(true);
        setPrescription(false);
        setUserInfos(false);
    }

    //Affichage des ordonnances
    const handlePrescription = () => {
        setOrder(false);
        setPrescription(true);
        setUserInfos(false);
    }

    //Affichage des informations personnelles
    const handleInfos = () => {
        setUserInfos(true);
        setOrder(false);
        setPrescription(false);
    }


    return  (
        <div className="relative z-20 ">
            <div className="mb-10">
                <h1 className="text-center text-3xl text-green-one pt-28 mb-10 mx-1 uppercase">Mon compte</h1>
                <Avatar user={userConnected}/>
                <p className="text-center text-green-one text-xl">{userConnected.displayName}</p>
            </div>
            <div className="flex flex-col  mx-10 lg:flex-row z-20">
                <button className="btn-account" onClick={handleOrder}>Mes commandes</button>
                <button className="btn-account" onClick={handlePrescription}>Mes ordonnances</button>
                <button className="btn-account" onClick={handleInfos}>Mes informations personnelles</button>
            </div>
            <div className="mb-20">
                <OrderList order={order} orders={orders} userConnected={userConnected}/>
                <PrescriptionList prescription={prescription} prescriptions={prescriptions} userConnected={userConnected}/>
                <UserInfos userinfos={userInfos} prescriptions={prescriptions} orders={orders} userConnected={userConnected}/>
            </div>
        </div>
    );
}

export default MyAccount;