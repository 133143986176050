import React from 'react';

//Loader, utile pour les routes privées lorsqu'on attend de savoir si l'user est co ou non
function Loader() {
    return (
        <div className="flex flex-col justify-center items-center text-green-one text-2xl my-52">
            <p>Veuillez patientez svp ...</p>
            <p>Chargement de la page en cours</p>
        </div>
    );
}

export default Loader;