import React, {useContext, useEffect, useState} from 'react';
import FirebaseContext from "../../contexts/FirebaseContext";
import ClickCollect from "../ClickCollect";
import HomeTitle from "../HomeTitle";

//Affichage des labos
//Pour réutiliser ce composant, il faudra changer les path
function Labo() {

    const firebase = useContext(FirebaseContext);
    const babyLabPath = "adherents/pharmacie-brisson/laboratoires/bebe";
    const sportLabPath = "adherents/pharmacie-brisson/laboratoires/minceur-sport";
    const beautyLabPath = "adherents/pharmacie-brisson/laboratoires/beaute-bien-etre";
    const vetLabPath = "adherents/pharmacie-brisson/laboratoires/veterinaire";

    const [error, setError] = useState("");


    //On récupère les laboratoires à afficher
    const getLab = path => {

        //On récupère les fichiers dans le storage en fonction d'un path
        firebase.getFileInStorage(path)
            .then(res => {

                res.items.forEach(item => {

                    //On récupère l'url du fichier et on s'en sert comme source pour créer une image
                    item.getDownloadURL()
                        .then(url => {

                            let img = document.createElement('img');
                            img.setAttribute('src', url);
                            img.classList.add("w-24", "sm:w-36", "mx-3", "lg:mx-10", "mb-5");

                            //On ajoute les img suivant leurs catégories >> un switch aurait pu être mieux là
                            if(path.includes("bebe")) {
                                document.getElementById("baby").appendChild(img);
                            } else if(path.includes("sport")) {
                                document.getElementById("sport").appendChild(img);
                            } else if(path.includes("beaute")) {
                                document.getElementById("beaute").appendChild(img);
                            } else if(path.includes("veterinaire")) {
                                document.getElementById("veto").appendChild(img);
                            }
                        })
                        .catch(error => {
                            setError(error.message);
                        })
                })
                setError("");
            })
            .catch(error => {
                setError("Erreur lors de la récupération des laboratoires ");
                console.log(error.message);
            })
    };

    //On récupère pour chaque catégorie (path différent dans le storage) les imgs
    useEffect(() => {

        getLab(babyLabPath);
        getLab(sportLabPath);
        getLab(beautyLabPath);
        getLab(vetLabPath);

    }, []);

    return (
        <>
            <HomeTitle/>
            <ClickCollect/>
            <section>
                <p className="text-center text-red-600">{error}</p>
                <div className="flex flex-col items-center mt-10 mb-10 mx-2">
                    <div className="mb-10">
                        <h1 className="text-center text-lg lg:text-2xl text-green-one">Les laboratoires bébé</h1>
                        <hr className="bg-gray-400 h-1"/>
                    </div>
                    <div id="baby" className="flex items-center justify-center flex-wrap mx-10 lg:mx-32"> </div>
                </div>
                <div className="flex flex-col items-center mt-10 mb-10 mx-2">
                    <div className="mb-10">
                        <h1 className="text-center text-lg lg:text-2xl  text-green-one">Les laboratoires minceur et sport</h1>
                        <hr className="bg-gray-400 h-1"/>
                    </div>
                    <div id="sport" className="flex items-center justify-center flex-wrap mx-10 lg:mx-32"> </div>
                </div>
                <div className="flex flex-col items-center mt-10 mb-10 mx-2">
                    <div className="mb-10">
                        <h1 className="text-center text-lg lg:text-2xl  text-green-one">Les laboratoires beauté & bien être</h1>
                        <hr className="bg-gray-400 h-1"/>
                    </div>
                    <div id="beaute" className="flex items-center justify-center flex-wrap mx-10 lg:mx-32"> </div>
                </div>
                <div className="flex flex-col items-center mt-10 mb-10 mx-2">
                    <div className="mb-10">
                        <h1 className="text-center text-lg lg:text-2xl  text-green-one">Les laboratoires vétérinaire</h1>
                        <hr className="bg-gray-400 h-1"/>
                    </div>
                    <div id="veto" className="flex items-center justify-center flex-wrap mx-10 lg:mx-32"> </div>
                </div>
            </section>
        </>
    );
}

export default Labo;