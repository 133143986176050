import {BrowserRouter, Route, Switch} from "react-router-dom";
import {useContext, useEffect, useState} from "react";

import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CookieConsent from "react-cookie-consent";
import 'react-slideshow-image/dist/styles.css';

import AuthContext from "./contexts/AuthContext";
import FirebaseContext from "./contexts/FirebaseContext";
import PharmacyContext from "./contexts/PharmacyContext";

import Footer from "./components/Footer";
import Header from "./components/Header";
import ErrorPage from "./components/ErrorPage";
import Home from "./components/Home";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import MyAccount from "./components/MyAccount";
import Scan from "./components/Scan";
import ScanStep from "./components/Scan/ScanStep";
import ScanFile from "./components/Scan/ScanFile";
import ScanConfirmation from "./components/Scan/ScanConfirmation";
import Services from "./components/Services";
import ForgetPassword from "./components/ForgetPassword";
import Labo from "./components/Labo";
import WhoAreWe from "./components/WhoAreWe";
import PrivateRoute from "./components/PrivateRoute";

function App() {

    const [userSession, setUserSession] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const firebase = useContext(FirebaseContext);

    //On verifie si l'user est connecté
    useEffect(() => {

        let listener = firebase.getUserConnected(user => {
            user ? setUserSession(user) : setUserSession(null);
            setIsLoading(false);
        })

        return () => { listener(); }
    }, [firebase, userSession, isLoading]);

    //On récupère les informations de la pharmacie
    //Pour utilisation de l'ensemble des fonctionnalités pour un autre site : changer l'id de la pharmacie
    const [pharmacyData, setPharmacyData] = useState("");

    useEffect(() => {

        firebase.getPharmacy("PHARMACIE BRISSON")
            .then(doc => {
                if(doc.exists && doc) {
                    setPharmacyData(doc.data());
                }
            })
            .catch(error => {
                console.log(error);
            });

    }, [firebase]);


    return (
        <AuthContext.Provider value={userSession}>
            <PharmacyContext.Provider value={pharmacyData}>
                <div id="App">
                    <ToastContainer
                        position="top-center"
                        autoClose={3000}
                        hideProgressBar
                        closeOnClick
                        pauseOnFocusLoss
                    />
                    <BrowserRouter>
                        <Header/>
                        <Switch>
                            <Route exact path="/" component={Home}/>
                            <Route exact path="/services" component={Services}/>
                            <Route exact path="/qui-sommes-nous" component={WhoAreWe}/>
                            <Route exact path="/laboratoires" component={Labo}/>
                            <Route exact path="/connexion" component={SignIn}/>
                            <Route exact path="/inscription" component={SignUp}/>
                            <PrivateRoute isLoading={isLoading} path="/mon-compte" component={MyAccount}/>
                            <Route path="/ordonnance" component={Scan}/>
                            <PrivateRoute isLoading={isLoading} path={"/ordonnance/etapes"} component={ScanStep}/>
                            <PrivateRoute isLoading={isLoading} path={"/ordonnance/choix-fichier"} component={ScanFile}/>
                            <PrivateRoute isLoading={isLoading} path="/ordonnance/confirmation-envoi" component={ScanConfirmation}/>
                            <Route exact path="/reinitialisation-connexion" component={ForgetPassword}/>
                            <Route component={ErrorPage}/>
                        </Switch>
                        <Footer/>
                        <CookieConsent location="bottom"
                                       buttonText="J'ai compris"
                                       cookieName="cookie-acceptation"
                                       style={{ background: "#393E46" }}
                                       buttonStyle={{ background: "#32E0C4", color: "#393E46", fontSize: "13px" }}
                        >Ce site utilise des cookies pour vous assurer de la meilleure expérience sur notre site internet</CookieConsent>
                    </BrowserRouter>
                </div>
            </PharmacyContext.Provider>
        </AuthContext.Provider>
    );
}

export default App;
