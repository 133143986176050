import React from 'react';

import ClickCollect from "../ClickCollect";
import HomeTitle from "../HomeTitle";
import Title from "../Title";

import frederique from "../../assets/img/FrederiqueTarot-carre.jpg";
import nathalie from "../../assets/img/NathalieGaudinoCelinePeplinski-carre.jpg";
import olivier from "../../assets/img/OlivierSoria-carre.jpg";

//Présentation de l'équipe de la pharmacie - page : qui-sommes-nous
function WhoAreWe() {

    return (
        <section>
            <HomeTitle/>
            <ClickCollect/>
            <div className="text-center my-10 mx-10 italic text-gray-400 lg:mx-24 lg:mt-20 lg:mb-14">
                « Au coeur du joli village provençal de St Chamas, la pharmacie Brisson vous offre son expertise
                depuis maintenant 20 ans dans différents domaines de santé :
                aromathérapie/phytothérapie, homéopathie, orthopédie, parapharmacie, soins vétérinaires et bébés… »
            </div>

            <Title title={"Notre équipe"}/>

            <div className="flex flex-col lg:flex-row justify-center items-center text-purple-one text-sm">
                <div className="w-4/5 lg:w-1/4 lg:mr-10 mb-10 lg:mb-0 flex flex-col justify-center">
                    <img src={frederique} alt="Frederique Tarot esthéticienne pharmacie brisson saint chamas"/>
                </div>
                <div className="w-4/5 lg:w-1/4 lg:mr-10 mb-10 lg:mb-0 flex flex-col justify-center">
                    <img src={nathalie} alt="Nathalie Gaudino & Celine Peplinski, phytothérapie pharmacie brisson saint chamas"/>
                </div>
                <div className="w-4/5 lg:w-1/4 lg:mr-10 mb-10 lg:mb-0 flex flex-col justify-center">
                    <img src={olivier} alt="Olivier Soria, rayon vétérinaire pharmacie brisson saint chamas"/>
                </div>
            </div>
            <div className="text-center my-5 lg:my-10 italic text-purple-one mx-3">
                Venez découvrir les autres membres de l'équipe en pharmacie !
            </div>
        </section>
    );
}

export default WhoAreWe;