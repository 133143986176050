import React, {Fragment, useContext, useRef} from 'react';
import {NavLink, useHistory} from 'react-router-dom';
import FirebaseContext from "../../../contexts/FirebaseContext";
import {toast} from "react-toastify";
import AuthContext from "../../../contexts/AuthContext";

function Navbar() {

    //Initialisation
    const mobileMenu = useRef(null);
    const history = useHistory();
    const firebase = useContext(FirebaseContext);
    const userConnected = useContext(AuthContext);


    //Cacher/afficher le menu mobile après un click
    const handleClick = (e) => {
        if(mobileMenu.current.classList.contains("hidden")) {
            mobileMenu.current.classList.remove("hidden");
        } else {
            mobileMenu.current.classList.add("hidden");
        }
    }

    //Déconnexion
    const signOut = e => {
        e.preventDefault()
        firebase.signOutUser();
        handleClick();
        toast("Vous êtes déconnecté !");
        history.push("/");
    }

    //Affichage différentiel du menu en fonction de la session user
    const connectedMenu = (userConnected) => {
        if (!userConnected) {
            return  <Fragment>
                        <li className="mb-1 lg:mb-0"><NavLink to="/connexion" className="btn-nav" onClick={handleClick}>Connexion</NavLink></li>
                        <li className="mb-1 lg:mb-0"><NavLink to="/inscription" className="btn-nav" onClick={handleClick}>Inscription</NavLink></li>
                    </Fragment>
        } else {
            return  <Fragment>
                        <li className="mb-1 lg:mb-0"><NavLink to="/mon-compte" className="btn-nav" onClick={handleClick}>Mon compte</NavLink></li>
                        <li className="mb-1 lg:mb-0"><NavLink to="/deconnexion" className="btn-nav" onClick={signOut}>Déconnexion</NavLink></li>
                    </Fragment>
        }
    }

    return (
        <nav id="navbar" className="flex items-center justify-between flex-wrap pt-1 lg:pt-3 lg:pb-3 pr-3 w-full relative z-50 top-0">
            {/*  Logo  */}
            <div className="flex">

            </div>

            {/*  Bouton mobile  */}
            <div className="block lg:hidden" onClick={handleClick}>
                <button id="nav-toggle" className="flex items-center text-white px-2 py-2">
                    <svg className="fill-current h-5 w-5" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path name="mobile-btn" d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
                    </svg>
                </button>
            </div>

            {/*  Menu */}
            <div ref={mobileMenu} className="w-full flex-grow hidden lg:flex lg:items-center lg:w-auto lg:block pt-6 lg:pt-0">
                <ul className="h-screen text-center lg:h-auto lg:flex flex-1 justify-end">
                    <li className="mb-1 lg:mb-0"><NavLink to="/" className="btn-nav" onClick={handleClick}>Accueil</NavLink></li>
                    <li className="mb-1 lg:mb-0"><NavLink to="/qui-sommes-nous" className="btn-nav" onClick={handleClick}>Qui sommes-nous ?</NavLink></li>
                    <li className="mb-1 lg:mb-0"><NavLink to="/laboratoires" className="btn-nav" onClick={handleClick}>Laboratoires</NavLink></li>
                    <li className="mb-1 lg:mb-0"><NavLink to="/services" className="btn-nav" onClick={handleClick}>Services</NavLink></li>
                    <li className="mb-1 lg:mb-0"><NavLink to="/ordonnance/etapes" className="btn-nav" onClick={handleClick}>Ordonnance</NavLink></li>
                    {connectedMenu(userConnected)}
                </ul>
            </div>
        </nav>
    );
}

export default Navbar;