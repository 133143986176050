import React, {Fragment, useContext, useRef, useState} from 'react';
import FirebaseContext from "../../../contexts/FirebaseContext";
import PharmacyContext from "../../../contexts/PharmacyContext";
import {useHistory} from "react-router-dom";
import {useInput} from "../../../utilsFunctions";
import plus from "../../../assets/img/SVG/plus.svg";

//L'user choisit son fichier d'ordonnance, le prévisualise et l'envoi
function ScanFile(props) {

    const dragZone = useRef(null);
    const errorMsg = useRef(null);
    const preview = useRef(null);
    const commentsArea = useRef(null);
    const [fileToSend, setFileToSend] = useState("");
    const comments = useInput("");
    const firebase = useContext(FirebaseContext);
    const userID = props.userConnected.uid;
    const pharmacy = useContext(PharmacyContext);
    const history = useHistory();
    const [vitalCard, setVitalCard] = useState("");
    const [mutualCard, setMutualCard] = useState("");

    //Validation du type de fichier : jpg, png ou pdf
    const fileTypes = ['image/jpeg', 'image/png', 'application/pdf'];

    const validFileType = file => {
        return fileTypes.includes(file.type);
    }

    //Prévisualisation de l'image
    const displayImage = (file, prescription) => {

        if(file.length > 1) {
            errorMsg.current.innerText = "Veuillez ne choisir qu'un seul document à la fois svp";
        } else if (file.length === 1) {

            errorMsg.current.innerText = "";

            //Si le type de fichier est valide et qu'il n'y en a qu'un de choisi, on cache la zone d'envoi et on prévisualise
            if(validFileType(prescription)) {

                dragZone.current.classList.add("hidden");
                setFileToSend(prescription);
                preview.current.classList.remove("hidden");

                if(prescription.type !== 'application/pdf') {

                    const image = document.createElement('img');
                    image.src = URL.createObjectURL(prescription);
                    image.classList.add("w-4/5", "max-w-screen-md");
                    image.id = "img-preview";
                    preview.current.appendChild(image);

                } else {

                    const iframe = document.createElement('iframe');
                    const url = window.URL.createObjectURL(prescription);
                    iframe.setAttribute('src', url);
                    iframe.classList.add("w-1/3", "lg:w-4/5");
                    iframe.id = "img-preview";
                    preview.current.appendChild(iframe);
                }
            } else {

                errorMsg.current.innerText = "Le format du document n'est pas valide";
            }
        }
    }

    //On annule le choix de l'image
    const handleCancel = e => {

        e.preventDefault();
        dragZone.current.classList.remove("hidden");
        preview.current.classList.add("hidden");
        dragZone.current.style = "";
        errorMsg.current.innerText = "";

        const img = document.getElementById("img-preview");

        if(img) {
            preview.current.removeChild(img);
        }
    }

    //Lorsqu'un fichier est choisi via l'input, on l'affiche
    const handleChange = () => {

        const input = document.getElementById("upload-image");
        const file = input.files;
        const prescription = file[0];
        displayImage(file, prescription);
    }

    //On vérifie que le format du doc de mutuelle choisi est valide
    const handleMutual = () => {

        const input = document.getElementById("mutual");
        const file = input.files[0];
        errorMsg.current.innerText = "";

        if(validFileType(file)) {
            setMutualCard(file)
        } else {
            errorMsg.current.innerText = "Le format du document de mutuelle n'est pas valide";
        }
    }

    //On vérifie que le format du doc de carte vitale est valide
    const handleVital = () => {

        const input = document.getElementById("vital");
        const file = input.files[0];
        errorMsg.current.innerText = "";

        if(validFileType(file)) {
            setVitalCard(file)
        } else {
            errorMsg.current.innerText = "Le format du document de sécurité sociale n'est pas valide";
        }

    }

    //On définit le chemin où le fichier sera enregistré dans le storage
    //Les documents peuvent être une ordonnance, une carte vitale ou une carte de mutuelle
    const definePath = file => {

        let path;

        if(file === mutualCard) {
            path = `photos/${userID}/mutuelle-${Date.now()}`;
        } else if (file === vitalCard) {
            path = `photos/${userID}/securite-sociale-${Date.now()}`;
        } else {
            path = `photos/${userID}/ordonnance-${Date.now()}`;
        }

        let pathExt;

        switch (file.type) {
            case "application/pdf" : pathExt = path + ".pdf"; break;
            case "image/png" : pathExt = path + ".png"; break;
            case "image/jpeg" :
            default : pathExt = path + ".jpg";
        }

        return pathExt;
    }

    //Ajout de l'ordonnance en BDD, on initialise les valeurs
    const addToDB = (url) => {

        //On enregistre l'ordonnance dans la DB Firestore
        const data = {
            authorID : userID,
            comment : comments.value,
            createdAt : new Date(),
            image : url,
            pharmacyEmail : "pharmaciebrissonclickandcollect@laposte.net",
            pharmaName : pharmacy.name,
            placeID : pharmacy.placeID,
            status : "SEND"
        }

        firebase.addPrescription(data)
            .then(() => {
                history.push("/ordonnance/confirmation-envoi");
            })
            .catch(error => {
                console.log("Erreur lors de l'ajout en DB : " +error.message);
                errorMsg.current.innerText = "Erreur lors de l'envoi de l'ordonnance, veuillez réessayer";
            })
    }

    //Méthode générique d'ajout d'un fichier dans le storage - utilisable pour les différents fichiers
    const addFileToStorage = (path, file) => {

        firebase.addFileInStorage(path, file)
            .then(() => {

                //Quand le fichier est ajouté au storage, on récupère l'URL que l'on ajoutera au profil user en BDD si carte vitale ou mutuelle
                //Si ordonnance, on ajoute l'ordonnance en base de données
                firebase.getURL(path)
                    .then(resp => {

                        if(file === mutualCard) {
                            firebase.updateUser(userID, {mutualCard : resp});
                        } else if (file === vitalCard) {
                            firebase.updateUser(userID, {vitalCard : resp});
                        } else {
                            addToDB(resp);
                        }
                    })
            })
            .catch((error) => {
                console.log("Erreur : " + error.message);
                errorMsg.current.innerText = "Erreur lors de l'envoi du document, veuillez réessayer";
            });

    }

    //On enregistre la carte mutuelle dans le storage avec le path défini précédemment
    const addMutualCardToStorage = () => {

        const pathExt = definePath(mutualCard);
        addFileToStorage(pathExt, mutualCard);
    }

    //On enregistre la carte vitale
    const addVitalCardToStorage = () => {

        const pathExt = definePath(vitalCard);
        addFileToStorage(pathExt, vitalCard);
    }

    //On enregistre l'ordonnance dans le storage
    const addPrescriptionToStorage = () => {

        const pathExt = definePath(fileToSend);
        addFileToStorage(pathExt, fileToSend);
    }

    //Soumission du formulaire
    const handleSubmit = e => {

        e.preventDefault();


        if(!fileToSend) {
            errorMsg.current.innerText = "Veuillez choisir un fichier svp";
        }

        if(mutualCard) {
            addMutualCardToStorage();
        }

        if(vitalCard) {
            addVitalCardToStorage();
        }

        addPrescriptionToStorage();
    }

    return (
        <Fragment>
            <form>
                <div>
                    <p ref={errorMsg} id="err-m" className="text-center text-red-600 p-4"> </p>
                </div>
                <div ref={dragZone} className="bg-gray-200 rounded-2xl py-10 mx-8 lg:mx-48 mb-5">
                    <label htmlFor="upload-image" className="cursor-pointer flex flex-col items-center">
                        <img src={plus} alt="Ajout de l'ordonnance" className="w-32 mb-8"/>
                        <p className="text-center text-gray-400">Ajoutez votre ordonnance</p>
                        <p className="text-center text-gray-400">cliquez pour choisir un fichier ou faites le glisser ici</p>
                        <p className="text-center text-gray-400 text-xs italic mt-2">Les formats autorisés : pdf, png, jpeg</p>
                    </label>
                    <input className="hidden" type="file" id="upload-image" name="upload-image" onChange={handleChange} accept="image/png, image/jpeg, application/pdf"/>
                </div>
                <div ref={preview} className="hidden flex flex-col-reverse items-center justify-center bg-gray-200 mx-10 lg:mx-48 rounded-2xl py-10">
                    <div ref={commentsArea} className="border border-2 mt-10 flex justify-center">
                        <textarea rows="5" cols="62" value={comments.value} onChange={comments.onChange} className="w-4/5 rounded-xl bg-gray-300 text-center focus:outline-none focus:ring-2 focus:ring-gray-400" placeholder="Souhaitez-vous insérer un commentaire supplémentaire ? (facultatif)"/>
                    </div>
                </div>
                <div className="text-gray-400 py-5 lg:py-10 mx-5 lg:mx-44 mb-3 lg:mb-5 flex flex-col items-start">
                    <p className="text-xs lg:text-sm mt-2 italic">Fichiers complémentaires (facultatif) : Afin de vous faire gagner du temps, si c'est la première fois que vous venez dans notre pharmacie,
                        vous pouvez nous joindre votre attestation de sécurité sociale et votre carte de mutuelle</p>
                    <p> </p>
                    <div className="flex flex-col lg:flex-row justify-center w-full my-5 text-xs lg:text-sm">
                        <div className="mb-2">
                            <label htmlFor="mutual">Carte de mutuelle : </label><input id="mutual" type="file" accept="image/png, image/jpeg, application/pdf" onChange={handleMutual}/>
                        </div>
                        <div>
                            <label htmlFor="vital">Attestation de sécurité sociale : </label><input id="vital" type="file" accept="image/png, image/jpeg, application/pdf" onChange={handleVital}/>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col lg:flex-row mx-28 lg:mx-96 my-5">
                    <button className="mr-5 btn-black mb-3 lg:mb-10" onClick={handleSubmit}>Envoyer</button><button className="btn-black lg:mb-10 mb-5" onClick={handleCancel}>Annuler</button>
                </div>
            </form>
        </Fragment>
    );
}

export default ScanFile;