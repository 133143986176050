import React, {useContext} from 'react';
import ScanStep from "./ScanStep";
import ScanFile from "./ScanFile";
import ScanConfirmation from "./ScanConfirmation";
import Home from "../Home";
import ScanDisconnected from "./ScanDisconnected";
import AuthContext from "../../contexts/AuthContext";

//Page pour les ordonnances
function Scan() {

    const userConnected = useContext(AuthContext);

    //Affichage différentiel en fonction des url, on affiche un composant différent
    const displayChildren = () => {

        let pathname = window.location.pathname;

            switch (pathname) {
                case "/ordonnance/etapes" : return <ScanStep/>;
                case "/ordonnance/choix-fichier" : return <ScanFile userConnected={userConnected}/>;
                case "/ordonnance/confirmation-envoi" : return <ScanConfirmation/>;
                default : return <Home/>;
            }
        }

    //Si l'user est connecté on affiche les étapes, sinon on affiche le message invitant à se connecter
    return !userConnected ?
         (
            <div>
                <div className="text-center text-3xl text-green-one mt-28 lg:mt-0 lg:pt-48 mb-24 mx-1 uppercase animate__animated animate__backInDown">Envoi ordonnance</div>
                <ScanDisconnected/>;
            </div>
        )

    :  (
        <div className="relative z-10">
            <div className="text-center text-3xl text-green-one mt-28 mb-24 mx-1 uppercase">Envoi ordonnance</div>
            {displayChildren()}
        </div>

    );
}

export default Scan;