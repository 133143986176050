import React, {useContext, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useInput} from "../../utilsFunctions";
import AuthContext from "../../contexts/AuthContext";
import FirebaseContext from "../../contexts/FirebaseContext";
import {toast} from "react-toastify";
import image from "../../assets/img/SVG/connexion.svg";

//Mot de passe oublié
function ForgetPassword() {

    //Initialisation
    const email = useInput("");
    const history = useHistory();
    const firebase = useContext(FirebaseContext);
    const [error, setError] = useState("");


    //Si l'utilisateur est connecté et qu'il tente d'accéder à la page, on le renvoit sur la page d'erreur
    const userConnected = useContext(AuthContext);

    useEffect(() => {
        if (userConnected) {
            history.push("/error");
        }
    }, [userConnected, history]);


    //Faire disparaitre le footer sur cette page
    useEffect(() => {

        document.querySelector("footer").classList.add("hidden");

        return () => {
            document.querySelector("footer").classList.remove("hidden");
        }
    }, []);

    //Disabled le bouton tant que l'email n'est pas rempli
    const disabled = email.value === "";

    //Validation du formulaire mot de passe oublié
    const handleSubmit = e => {

        e.preventDefault();
        firebase.resetPassword(email.value)
            .then(() => {
                toast("Un email de réinitialisation du mot de passe a été envoyé");
                history.push("/connexion");
            })
            .catch(error => {
                setError(error);
                email.empty();
            })
    }

    //Affichage des erreurs du formulaire d'inscription
    const errorMsg = error && <span className="text-red-600 mb-20">{error.message}</span>

    return (

        <div className="relative flex justify-around mt-0 pt-40 z-0">
            <aside className="hidden lg:flex justify-center lg:w-1/2 lg:pl-36">
                <img src={image} alt="Connexion" className="w-96 h-auto"/>
            </aside>
            <section className="flex flex-col lg:w-1/2 lg:pr-36 mt-12 mx-10 lg:mx-0">
                <h1 className="text-center text-3xl text-green-one uppercase mb-6">Mot de passe oublié ?</h1>
                <p className="text-center text-sm text-gray-400 mb-8 xl:mb-14 my-6">Envoyer un email de récupération de mot de passe</p>
                {errorMsg}
                <form onSubmit={handleSubmit}>
                    <div className="flex flex-col items-center sm:mx-10 mt-6">
                        <div className="w-full">
                            <div className="flex justify-center text-gray-400 focus-within:text-gray-600 mb-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 22 24">
                                    <path id="Tracé_460" d="M25.989,10.842a2.629,2.629,0,0,0-1.034-2.147L15,2,5.045,8.695A2.638,2.638,0,0,0,4,10.842V23.474A2.379,2.379,0,0,0,6.2,26H23.8A2.379,2.379,0,0,0,26,23.474l-.011-12.632ZM15,17.158,5.914,10.634,15,4.526l9.086,6.107Z" transform="translate(-4 -2)" fill="currentColor"/>
                                </svg>
                                <input className="text-center focus:outline-none ml-2" type="email" placeholder="Email" value={email.value} onChange={email.onChange}/>
                            </div>
                            <hr className="border-none h-0.5 bg-green-one mb-5"/>
                        </div>
                        <div className="flex justify-center lg:mx-10 xl:mx-16 mb-10">
                            <button disabled={disabled} className="bg-gray-400 px-3 my-10 btn-black" type="submit">Récupérer mot de passe</button>
                        </div>
                    </div>
                </form>
            </section>
        </div>
    );
}

export default ForgetPassword;