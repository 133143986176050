import React, {Fragment} from 'react';
import {Link} from "react-router-dom";

import number1 from "../../../assets/img/SVG/1.svg"
import number2 from "../../../assets/img/SVG/2.svg"
import number3 from "../../../assets/img/SVG/3.svg"

//Affichage des étapes de l'envoi d'ordonnance
function ScanStep() {

    return (
        <Fragment>
            <div className="lg:flex flex-row justify-around h-full mx-3 sm:mx-10 lg:mx-44">
                <div className="bg-white flex flex-col items-center border rounded-3xl mb-3 lg:w-1/3 py-10 shadow-lg lg:mx-10">
                    <img src={number1} alt="Etape 1" className="w-10 mb-12"/>
                    <p className="text-center mb-16 text-gray-400">Scannez ou photographiez votre ordonnance puis joignez le fichier</p>
                </div>
                <div className="bg-white flex flex-col items-center border rounded-3xl mb-3 lg:w-1/3 py-10 shadow-lg lg:mx-10">
                    <img src={number2} alt="Etape 2" className="w-10 mb-12"/>
                    <p className="text-center mb-12 text-gray-400">Nous préparons l’ordonnance et vous prévenons lorsqu’elle est prête</p>
                </div>
                <div className="bg-white flex flex-col items-center border rounded-3xl mb-3 lg:w-1/3 py-10 shadow-lg lg:mx-10">
                    <img src={number3} alt="Etape 3" className="w-10 mb-12"/>
                    <p className="text-center mb-12 text-gray-400">Venez la récupérer en pharmacie</p>
                </div>
            </div>
            <div className="flex justify-center w-full">
                <Link to="/ordonnance/choix-fichier" className="w-56 lg:w-1/6 mt-16 mb-24"><button className="btn-black">Commencer</button></Link>
            </div>
        </Fragment>
    );
}

export default ScanStep;