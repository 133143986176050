import React, {useContext, useEffect, useState} from 'react';
import {getFirebaseErrorMessage, useInput} from "../../utilsFunctions";
import {Link, useHistory} from "react-router-dom";
import {toast} from "react-toastify";

import FirebaseContext from "../../contexts/FirebaseContext";
import AuthContext from "../../contexts/AuthContext";
import PharmacyContext from "../../contexts/PharmacyContext";

import image from "../../assets/img/SVG/connexion.svg";

//Inscription
function SignUp() {

    //Initialisation
    const history = useHistory();
    const pharmacyData = useContext(PharmacyContext);
    const firebase = useContext(FirebaseContext);
    const userConnected = useContext(AuthContext);
    const [error, setError] = useState("");

    const name = useInput("");
    const email = useInput("");
    const password = useInput("");
    const phone = useInput("");
    const confirmPassword = useInput("");

    //Si l'utilisateur est connecté et qu'il tente d'accéder à la page, on le renvoit sur la page d'erreur
    useEffect(() => {
        if (userConnected) {
            history.push("/error");
        }
    }, [userConnected, history]);

    //Faire disparaitre le footer sur cette page
    useEffect(() => {
        document.querySelector("footer").classList.add("hidden");

        return () => {
            document.querySelector("footer").classList.remove("hidden");
        }
    }, []);


    //Disabled le bouton tant que tous les champs ne sont pas remplis
    const btn = name.value && email.value && password.value && confirmPassword.value ?
        <button className="btn-black mt-6 px-3" type="submit">S'inscrire</button> : <button className="btn-black cursor-not-allowed mt-6 px-3" type="submit" disabled>S'inscrire</button>


    //Verification du format du téléphone
    const handlePhone = () => {

        const phone = document.getElementById('phone');
        phone.validity.patternMismatch ? phone.setCustomValidity("Le format du téléphone doit être 02XXXXXXXX ou +3306XXXXXXXX par ex") : phone.setCustomValidity("");
    }

    //Vérification du format du nom
    const handleName = () => {

        const name = document.getElementById('name');
        name.validity.patternMismatch ? name.setCustomValidity("Les chiffres et caractères spéciaux ne sont pas autorisés") : name.setCustomValidity("");
    }

    //Vérification du format du MDP
    const handlePassword = () => {

        const password = document.getElementById('password-v');
        password.validity.patternMismatch ? password.setCustomValidity("Le mot de passe ne respecte pas les critères de sécurité") : password.setCustomValidity("");
    }

    //Validation du formulaire d'inscription, persistance de l'user en BDD + redirection à l'accueil w/ toast
    const submit = async e => {

        e.preventDefault();

        if(password.value !== confirmPassword.value) {
            setError("Le mot de passe et la confirmation ne correspondent pas !");
            return;
        }

        try {

            //On set la session + inscription de l'user - quand il est inscrit on récup l'id pour la persistance en BDD
            await firebase.setSession();
            const user = await firebase.signUpUser(email.value, password.value);
            let id = user.user.uid;

            //On set les données à persister
            const data = {
                id : id,
                email: email.value,
                phone: phone.value.trim(),
                fullName: name.value.trim(),
                pharmacy: {
                    name: pharmacyData.name,
                    email: pharmacyData.email,
                    place_id: pharmacyData.placeID
                }
            };

            //Ajout de l'user en BDD
            await firebase.addUser(id, data);

            //On set l'avatar par défault
            const avatarInfo = {
                authorID: id,
                avatar : "https://firebasestorage.googleapis.com/v0/b/brisson-test.appspot.com/o/avatars%2Fdefault%2FPikPng.com_avatar-png_368328.png?alt=media&token=a0eed1c6-9f23-4b49-877e-bad2a6a95396",
                createdAt: new Date()
            }

            //On ajoute l'avatar en BDD
            await firebase.addAvatar(id, avatarInfo);

            //On ajoute le nom au profil utilisateur (dispo via l'auth)
            await firebase.updateProfil({
                displayName : name.value
            });

            toast("Vous êtes bien inscrit !");
            window.location.href = "/mon-compte";

        } catch (error) {
            setError(getFirebaseErrorMessage(error.code));

            //Si le code est invalid-argument == les données ne respectent pas les regles de sécu dans Firestore donc on déco + suppr l'user
            if(error.code === "invalid-argument") {
                firebase.signOutUser();
                firebase.deleteUser();
                toast.error("Données du formulaire invalides, veuillez réessayer");
            }
        }
    }


    //Affichage des erreurs du formulaire d'inscription
    const errorMsg = error && <p className="text-sm text-red-600 text-center mb-10">{error}</p>

    return (

        <div className="relative z-10 flex justify-around mt-10 lg:mt-0 lg:pt-48">

            <aside className="hidden lg:flex justify-center lg:w-1/2  lg:pl-36">
                <img src={image} alt="Inscription pharmacie brisson saint chamas" className="w-96 h-auto"/>
            </aside>

            <section className="flex-col lg:w-1/2 lg:pr-36 mt-8 mx-10 lg:mr-0 lg:ml-10">
                <h1 className="text-center text-3xl text-green-one uppercase mb-9">Inscription</h1>
                <p className="text-center text-sm text-gray-400 mb-10  mt-6">Inscrivez-vous pour accéder à tous vos services de commandes et ordonnances</p>

                {errorMsg}

                <form onSubmit={submit}>
                    <div className="flex flex-col lg:mx-10 xl:mx-20">
                        <div className="flex flex-col lg:flex-row justify-around">
                            <div className="flex flex-col items-center lg:mx-4">
                                <div className="w-full">
                                    <div className="flex justify-center text-gray-400 focus-within:text-gray-600 mb-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="21.708" height="24.325" viewBox="0 0 21.708 24.325">
                                            <path id="person_icon_184772" d="M12.854,14.162c3,0,5.427-2.723,5.427-6.081S15.851,2,12.854,2,7.427,4.723,7.427,8.081,9.857,14.162,12.854,14.162Zm3.618-6.081a3.855,3.855,0,0,1-3.618,4.054A3.855,3.855,0,0,1,9.236,8.081a3.855,3.855,0,0,1,3.618-4.054A3.855,3.855,0,0,1,16.472,8.081ZM23.708,24.3A1.848,1.848,0,0,1,21.9,26.325H3.809A1.848,1.848,0,0,1,2,24.3c0-2.027,1.809-8.108,10.854-8.108S23.708,22.271,23.708,24.3ZM21.9,24.29a5.72,5.72,0,0,0-1.505-3.373c-1.179-1.322-3.4-2.7-7.54-2.7s-6.36,1.378-7.54,2.7A5.732,5.732,0,0,0,3.809,24.29Z" transform="translate(-2 -2)" fill="currentColor"/>
                                        </svg>
                                        <input className="text-center focus:outline-none" type="text" placeholder="Nom et Prénom" id="name" value={name.value} onChange={name.onChange} onKeyUp={handleName} maxLength='55' required/>
                                    </div>
                                    <hr className="border-none h-0.5 bg-green-one mb-5"/>
                                </div>
                                <div className="w-full">
                                    <div className="flex justify-center text-gray-400 focus-within:text-gray-600 mb-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 22 24">
                                            <path id="Tracé_460" d="M25.989,10.842a2.629,2.629,0,0,0-1.034-2.147L15,2,5.045,8.695A2.638,2.638,0,0,0,4,10.842V23.474A2.379,2.379,0,0,0,6.2,26H23.8A2.379,2.379,0,0,0,26,23.474l-.011-12.632ZM15,17.158,5.914,10.634,15,4.526l9.086,6.107Z" transform="translate(-4 -2)" fill="currentColor"/>
                                        </svg>
                                        <input className="text-center focus:outline-none" type="email" placeholder="Email" value={email.value} onChange={email.onChange} maxLength='55' required/>
                                    </div>
                                    <hr className="border-none h-0.5 bg-green-one mb-5"/>
                                </div>
                                <div className="w-full">
                                    <div className="flex justify-center text-gray-400 focus-within:text-gray-600 mb-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22.781" height="22.781" viewBox="0 0 22.781 22.781">
                                            <path id="solid_phone-alt" d="M22.131,16.1l-4.983-2.136a1.068,1.068,0,0,0-1.246.307l-2.207,2.7A16.493,16.493,0,0,1,5.811,9.088l2.7-2.207a1.065,1.065,0,0,0,.307-1.246L6.679.652A1.075,1.075,0,0,0,5.455.033L.828,1.1A1.068,1.068,0,0,0,0,2.142,20.643,20.643,0,0,0,20.646,22.788a1.068,1.068,0,0,0,1.041-.828l1.068-4.628a1.081,1.081,0,0,0-.623-1.228Z" transform="translate(0 -0.006)" fill="currentColor"/>
                                        </svg>
                                        <input className="text-center focus:outline-none" type="text"  name="phone" id="phone" placeholder="Téléphone (facultatif)" onKeyUp={handlePhone} value={phone.value} maxLength='20' pattern="(0|\+33|0033)[1-9][0-9]{8}" onChange={phone.onChange}/>
                                    </div>
                                    <hr className="border-none h-0.5 bg-green-one mb-5"/>
                                </div>
                            </div>
                            <div className="flex flex-col items-center">
                                <div className="w-full tooltip">
                                    <div className="flex justify-center text-gray-400 focus-within:text-gray-600 mb-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.291" height="24.007" viewBox="0 0 18.291 24.007">
                                            <path id="Tracé_463" d="M24,10H22.862V7.716a5.716,5.716,0,1,0-11.432,0V10H10.286A2.286,2.286,0,0,0,8,12.289V23.721a2.286,2.286,0,0,0,2.286,2.286H24a2.286,2.286,0,0,0,2.286-2.286V12.289A2.286,2.286,0,0,0,24,10ZM17.146,20.291A2.286,2.286,0,1,1,19.432,18,2.286,2.286,0,0,1,17.146,20.291ZM20.689,10H13.6V7.716a3.544,3.544,0,1,1,7.088,0Z" transform="translate(-8 -2)" fill="currentColor"/>
                                        </svg>
                                        <input id="password-v" className="text-center focus:outline-none" type="password" placeholder="Mot de passe" onKeyUp={handlePassword} value={password.value} onChange={password.onChange} required pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[-+!*$@%_])([-+!*$@%_\w]{8,20})$"/>
                                    </div>
                                    <hr className="border-none h-0.5 bg-green-one mb-5"/>
                                    <span className="text-xs text-gray-400 tooltiptext">Entre 8 et 20 caractères avec au min. 1 lettre minuscule, 1 lettre majuscule, 1 chiffre, 1 caractère spécial parmi ($ @ % * + - _ !)</span>
                                </div>
                                <div className="w-full">
                                    <div className="flex justify-center text-gray-400 focus-within:text-gray-600 mb-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.291" height="24.007" viewBox="0 0 18.291 24.007">
                                            <path id="Tracé_463" d="M24,10H22.862V7.716a5.716,5.716,0,1,0-11.432,0V10H10.286A2.286,2.286,0,0,0,8,12.289V23.721a2.286,2.286,0,0,0,2.286,2.286H24a2.286,2.286,0,0,0,2.286-2.286V12.289A2.286,2.286,0,0,0,24,10ZM17.146,20.291A2.286,2.286,0,1,1,19.432,18,2.286,2.286,0,0,1,17.146,20.291ZM20.689,10H13.6V7.716a3.544,3.544,0,1,1,7.088,0Z" transform="translate(-8 -2)" fill="currentColor"/>
                                        </svg>
                                        <input className="text-center focus:outline-none" id="confirmPassword" type="password" placeholder="Répéter mot de passe" value={confirmPassword.value} onChange={confirmPassword.onChange} required/>
                                    </div>
                                    <hr className="border-none h-0.5 bg-green-one mb-5"/>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="flex justify-center mx-16 mb-10">
                                {btn}
                            </div>
                            <div className="text-xs md:text-sm text-gray-400 mt-6 mb-10">
                                <hr className="border-none h-0.5 bg-gray-400 mt-6"/>
                                <p className="mt-2 hover:text-green-one"><Link to="/connexion">Déjà un compte ? Connectez-vous</Link></p>
                            </div>
                        </div>

                    </div>
                </form>
            </section>
        </div>
    );
}

export default SignUp;