import React, {useContext} from 'react';
import PharmacyContext from "../../contexts/PharmacyContext";
import adress from "../../assets/img/SVG/adresse.svg";
import mail from "../../assets/img/SVG/mail.svg";
import phone from "../../assets/img/SVG/phone.svg";
import wave from "../../assets/img/SVG/wave-footer.svg";
import Title from "../Title";

function Footer() {

    //On récupère les informations de la pharmacie
    const pharmacyData = useContext(PharmacyContext);

    return (
      <footer id="footer" className="mt-16 relative">
        <img
          src={wave}
          alt="Vague décorative pied de page pharmacie brisson st chamas"
          className="absolute bottom-0 z-0"
        />
        <div className="relative z-0">
          <div className="lg:mb-24">
            <Title title={"Nous contacter"} />
          </div>
          <div className="text-white flex flex-col items-center lg:flex-row justify-around lg:mb-16">
            <div className="w-full h-52 lg:w-1/3 flex justify-center">
              <iframe
                allowFullScreen=""
                className="w-full lg:w-auto"
                loading="lazy"
                title="maps"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2891.6890145022926!2d5.031960115775061!3d43.55052406708146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12b602ebfc786a4b%3A0x399d837e97874bd9!2sPharmacie%20Brisson!5e0!3m2!1sfr!2sfr!4v1619020191544!5m2!1sfr!2sfr"
              ></iframe>
            </div>
            <div className="w-full lg:w-1/3 m-2 text-gray-400 lg:text-white">
              <div className="mx-5 lg:mx-0">
                <p className="text-xl mb-3">Coordonnées</p>
                <hr className="border bg-white" />
              </div>

              <div className="flex mt-3">
                <div className="text-white">
                  <img
                    src={adress}
                    alt="icon-location pharmacie brisson st chamas"
                    className="lg:mr-3 mb-3"
                  />
                  <img
                    src={mail}
                    alt="icon-mail pharmacie brisson st chamas"
                    className="lg:mr-3 mb-3"
                  />
                  <img
                    src={phone}
                    alt="icon-phone pharmacie brisson st chamas"
                    className="lg:mr-3"
                  />
                </div>

                <div className="text-base">
                  <p className="mb-2">
                    <a
                      href="https://www.google.com/maps?ll=43.55052,5.034149&z=15&t=m&hl=fr&gl=FR&mapclient=embed&cid=4151619011236809689"
                      className="hover:text-green-300 hover:underline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {pharmacyData.address}
                    </a>
                  </p>

                  <p className="mb-0 mt-4">
                    <a
                      href={`mailto:${pharmacyData.email}`}
                      className="hover:text-green-300 hover:underline"
                    >
                      {pharmacyData.email}
                    </a>
                  </p>
                  <p className="mb-0 mt-4">
                    <a
                      href={`tel:${pharmacyData.phone}`}
                      className="hover:text-green-300 hover:underline"
                    >
                      {pharmacyData.phone}
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-1/3 p-5 m-2 bg-opacity-20 text-gray-400 lg:text-white">
              <div>
                <p className="text-xl mb-3">Horaires d'ouverture</p>
                <hr className="border bg-white" />
              </div>
              <div className="mt-3">
                <div className="text-base flex flex-col lg:flex-row">
                  <p className="mb-3">Du lundi au vendredi : </p>
                  <p className="mb-3 lg:ml-4">08:30 – 12:30, 14:30 – 19:00</p>
                </div>
                <div className="text-base flex">
                  <p className="mb-3">Samedi : </p>
                  <p className="ml-4">08:30 – 12:30, 15:00 – 19:00</p>
                </div>
                <div className="text-base flex">
                  <p className="mb-3">Dimanche : </p>
                  <p className="ml-4">Fermé</p>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center text-white text-xs lg:flex flex-row justify-center mt-5 mb-3">
            <p className="mb-2 lg:m-2">
              <a
                rel="noreferrer"
                target="_blank"
                href="https://firebasestorage.googleapis.com/v0/b/katalys-my-scan-bdd.appspot.com/o/adherents%2Fpharmacie-brisson%2Fpolitique_de_confidentialit%C3%A9_brisson.pdf?alt=media&token=f7c7b47a-fde1-433a-a1bd-d7fc3bc12eb3"
              >
                {" "}
                Mentions légales{" "}
              </a>{" "}
              -
              <a
                rel="noreferrer"
                target="_blank"
                href="https://firebasestorage.googleapis.com/v0/b/katalys-my-scan-bdd.appspot.com/o/adherents%2Fpharmacie-brisson%2Fmentions_legales_brisson.pdf?alt=media&token=d53c299c-6af5-4ccc-bdb0-445e5e97431b"
              >
                {" "}
                Politique de confidentialité
              </a>
            </p>
            <p className="lg:m-2">
              Une création Katalys pour la pharmacie Brisson
            </p>
            <p className="mb-2 lg:m-2"> © Tous droits réservés</p>
          </div>
        </div>
      </footer>
    );
}

export default Footer;