import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import picture from "../../assets/img/SVG/error.svg";

function ErrorPage() {

    //Faire disparaitre le footer sur cette page
    useEffect(() => {

        document.querySelector("footer").classList.add("hidden");

        return () => {
            document.querySelector("footer").classList.remove("hidden");
        }
    }, []);


    return (
        <div className="mx-5 lg:mx-24 flex justify-center lg:justify-end">
            <aside className="hidden lg:flex absolute bottom-0 left-10 w-2/5">
                <img src={picture} alt="Erreur"/>
            </aside>
            <section className="flex flex-col items-center mt-52 lg:w-1/2">
                <h1 className="text-center text-3xl lg:text-5xl text-green-one italic">Oups, cette page est introuvable</h1><br/>
                <div className="lg:w-1/3">
                    <Link to="/"><button className="btn-black mt-6 px-3 lg:px-1">Retour à l'accueil</button></Link>
                </div>
            </section>
        </div>
    );
}

export default ErrorPage;