import React from 'react';
import {Link} from "react-router-dom";

import styled, {keyframes} from "styled-components";
import {flipInX} from "react-animations";

//Animation
const FlipIn = styled.div`animation: 1s ${keyframes`${flipInX}`}`;

//Si l'user est déco on affiche ce composant l'invitant à se connecter/créer un compte
function ScanDisconnected() {
    return (
        <FlipIn>
            <div className="flex flex-col items-center mx-1">
                <p className="text-center text-gray-400">Vous devez être connecté pour accéder au service d'envoi d'ordonnances</p>
                <div className="flex flex-col items-center justify-center lg:flex-row w-full lg:w-2/5 my-16">
                    <Link className="w-3/4 lg:mx-9" to="/connexion"><button className="btn-black lg:my-6 mb-5">Connexion</button></Link>
                    <Link className="w-3/4 lg:mx-9" to="/inscription"><button className="btn-black lg:my-6">Inscription</button></Link>
                </div>
            </div>
        </FlipIn>
    );
}

export default ScanDisconnected;