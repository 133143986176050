import React from 'react';
import {Link} from "react-router-dom";

import HomeTitle from "../HomeTitle";
import Title from "../Title";

import medadom from "../../assets/img/Cabine Medadom (2).png"
import caddie from "../../assets/img/SVG/caddie.svg";
import scan from "../../assets/img/SVG/scan.svg";
import teleconsultation from "../../assets/img/SVG/teleconsultation.svg";
import herbal from "../../assets/img/SVG/herbal-medicine.svg";
import homeo from "../../assets/img/SVG/homeopathy.svg";
import ortho from "../../assets/img/SVG/orthopedics.svg";
import drugstore from "../../assets/img/SVG/drugstore.svg";
import baby from "../../assets/img/SVG/baby.svg";
import vet from "../../assets/img/SVG/vet.svg";

//Affichage des services de la pharmacie - page : /services
function Services() {

    return (
        <>
            <HomeTitle/>
            <article className="w-full">
                <img className="w-full" src={medadom} alt="Téléconsultation Médadom pharmacie brisson saint chamas"/>
            </article>

            <section className="mt-20">

                <Title title={"Nos services"}/>

                <div className="flex flex-col items-center md:flex-row md:justify-around md:items-end mt-10 lg:mt-20 md:mx-24 lg:mx-56 text-gray-400">
                    <figure className="flex flex-col items-center mb-10 lg:mb-0 transform hover:scale-125">
                        <img src={scan} alt="Logo scan ordonnance pharmacie brisson saint chamas" className="mb-6"/>
                        <figcaption>Scan d'ordonnance</figcaption>
                    </figure>
                    <figure className="flex flex-col items-center mb-10 lg:mb-0 transform hover:scale-125">
                        <img src={teleconsultation} alt="Logo téléconsultation pharmacie brisson saint chamas" className="mb-6"/>
                        <figcaption>Téléconsultation Médadom</figcaption>
                    </figure>
                    <figure className="flex flex-col items-center mb-10 lg:mb-0 transform hover:scale-125">
                        <img src={caddie} alt="Logo chariot pour le Click&Collect pharmacie brisson saint chamas" className="mb-6"/>
                        <figcaption>Click & Collect</figcaption>
                    </figure>
                </div>

                <h2 className="text-center my-10 lg:my-20 text-gray-400 mx-4">Notre expertise s'étend également sur plusieurs domaines : </h2>

                <div className="flex flex-col items-center md:flex-row md:justify-around items-end lg:mx-32 text-gray-400">
                    <figure className="flex flex-col items-center mb-10 md:mb-0 transform hover:scale-125">
                        <img src={herbal} alt="Phytothérapie pharmacie brisson saint chamas" className="h-16 mb-3 lg:mb-7"/>
                        <figcaption>Phytothérapie</figcaption>
                    </figure>
                    <figure className="flex flex-col items-center mb-10 md:mb-0 transform hover:scale-125">
                        <img src={homeo} alt="Homéopathie pharmacie brisson saint chamas" className="h-16 mb-3 lg:mb-7"/>
                        <figcaption>Homéopathie</figcaption>
                    </figure>
                    <figure className="flex flex-col items-center mb-10 md:mb-0 transform hover:scale-125">
                        <img src={ortho} alt="Orthopédie pharmacie brisson saint chamas" className="h-16 mb-3 lg:mb-7"/>
                        <figcaption>Orthopédie</figcaption>
                    </figure>
                    <figure className="flex flex-col items-center mb-10 md:mb-0 transform hover:scale-125">
                        <img src={drugstore} alt="Parapharmacie pharmacie brisson saint chamas" className="h-16 mb-3 lg:mb-7"/>
                        <figcaption>Parapharmacie</figcaption>
                    </figure>
                    <figure className="flex flex-col items-center mb-10 md:mb-0 transform hover:scale-125">
                        <img src={baby} alt="Bébé pharmacie brisson saint chamas" className="h-16 mb-3 lg:mb-7"/>
                        <figcaption>Bébé</figcaption>
                    </figure>
                    <figure className="flex flex-col items-center mb-10 md:mb-0 transform hover:scale-125">
                        <img src={vet} alt="Vétérinaire pharmacie brisson saint chamas" className="h-16 mb-3 lg:mb-7"/>
                        <figcaption>Vétérinaire</figcaption>
                    </figure>

                </div>
                <div className="flex flex-row w-full justify-center">
                    <h2 className="bg-green-one rounded-3xl py-3 mx-2 lg:w-1/3 text-center hover:text-gray-400 my-10 text-white italic"><Link to="/qui-sommes-nous">Cliquez ici pour découvrir notre équipe de professionnels</Link></h2>
                </div>
                
            </section>
        </>
    );
}

export default Services;