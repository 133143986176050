import React, {useContext} from 'react';
import {Link} from "react-router-dom";

import PharmacyContext from "../../contexts/PharmacyContext";

import News from "./News";
import ClickCollect from "../ClickCollect";
import PromotionsSlider from "../PromotionsSlider";
import HomeTitle from "../HomeTitle";

//images
import inside from "../../assets/img/pharma-inte.jpg";
import outside from "../../assets/img/pharma-exte.jpg";
import bgHours from '../../assets/img/bg-horaires.png';
import bgCoordonnees from '../../assets/img/Groupe 622.png';
//Page d'accueil : /
function Home() {

    //On récupère les informations de la pharmacie
    const pharmacyData = useContext(PharmacyContext);

    return (
      <div className="">
        <HomeTitle />
        <ClickCollect />
        <PromotionsSlider />
        <main className="lg:grid lg:grid-cols-2 mx-4 lg:mx-20 mt-16 lg:mb-16">
          <div className="flex flex-col justify-center items-center">
            <img
              src={outside}
              loading="lazy"
              alt="Devanture de la pharmacie brisson saint chamas"
              className="h-52"
            />
            <p className="text-center text-sm lg:w-1/2 italic my-10">
              Découvrez l'équipe de professionnels de votre pharmacie, en
              cliquant{" "}
              <Link
                className="hover:text-green-one font-bold"
                to="/qui-sommes-nous"
              >
                ici
              </Link>{" "}
              ainsi que les services proposés en cliquant{" "}
              <Link
                className="font-bold hover:text-green-one"
                to="/qui-sommes-nous"
              >
                ici
              </Link>
            </p>
            <img
              src={inside}
              loading="lazy"
              alt="Vue intérieure de la pharmacie brisson saint chamas"
              className="h-72"
            />
          </div>
          <div className="border rounded-3xl shadow-lg flex flex-col justify-between mt-10 lg:mt-0 ml-2">
            <div className="self-center w-full lg:w-3/4">
              <img src={bgHours} alt="pharmacie brisson saint chamas" />
            </div>
            <div className="self-center mt-2">
              <p className="text-xl text-green-one">Coordonnées</p>
              <hr className="border border-green-one bg-purple-one" />
            </div>
            <div className="flex items-center mb-3 mt-6 mx-2 lg:mx-24 text-gray-400">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.846"
                  height="21.128"
                  viewBox="0 0 15.846 21.128"
                  className="mb-2"
                >
                  <path
                    id="solid_map-marker-alt"
                    d="M6.6,20.71C.607,12.19,0,10.9,0,7.766A7.846,7.846,0,0,1,7.923,0a7.846,7.846,0,0,1,7.923,7.766c0,3.131-.607,4.424-6.6,12.944A2.3,2.3,0,0,1,6.6,20.71Zm1.32-10.355c1.823,0,2.641-.8,2.641-2.589S9.746,3.883,7.923,3.883A4.5,4.5,0,0,0,3.961,7.766C3.961,9.553,6.1,10.355,7.923,10.355Z"
                    fill="currentColor"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22.155"
                  height="15"
                  viewBox="0 0 22.155 15"
                  className="mb-2 mt-5"
                >
                  <path
                    id="solid_envelope"
                    d="M21.736,68.953a.264.264,0,0,1,.42.184v7.988A1.984,1.984,0,0,1,20.078,79h-18A1.984,1.984,0,0,1,0,77.125V69.141a.262.262,0,0,1,.42-.184c.969.68,2.254,1.543,6.668,4.438.913.6,2.454,1.867,3.99,1.859,1.545.012,3.116-1.281,3.994-1.859C19.485,70.5,20.766,69.633,21.736,68.953ZM11.078,74c1,.016,2.449-1.141,3.176-1.617,5.742-3.762,6.179-4.09,7.5-5.027a.905.905,0,0,0,.4-.738v-.742A1.984,1.984,0,0,0,20.078,64h-18A1.984,1.984,0,0,0,0,65.875v.742a.91.91,0,0,0,.4.738c1.324.934,1.761,1.266,7.5,5.027C8.628,72.859,10.074,74.016,11.078,74Z"
                    transform="translate(0 -64)"
                    fill="currentColor"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22.781"
                  height="22.781"
                  viewBox="0 0 22.781 22.781"
                  className="mb-2 mt-4"
                >
                  <path
                    id="solid_phone-alt"
                    d="M22.131,16.1l-4.983-2.136a1.068,1.068,0,0,0-1.246.307l-2.207,2.7A16.493,16.493,0,0,1,5.811,9.088l2.7-2.207a1.065,1.065,0,0,0,.307-1.246L6.679.652A1.075,1.075,0,0,0,5.455.033L.828,1.1A1.068,1.068,0,0,0,0,2.142,20.643,20.643,0,0,0,20.646,22.788a1.068,1.068,0,0,0,1.041-.828l1.068-4.628a1.081,1.081,0,0,0-.623-1.228Z"
                    transform="translate(0 -0.006)"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div className="ml-3">
                <p className="mb-2">
                  <a
                    href="https://www.google.com/maps?ll=43.55052,5.034149&z=15&t=m&hl=fr&gl=FR&mapclient=embed&cid=4151619011236809689"
                    className="hover:text-green-300 hover:underline text-lg"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {pharmacyData.address}
                  </a>
                </p>
                <p className="mb-2 mt-4">
                  <a
                    href={`mailto:${pharmacyData.email}`}
                    className="hover:text-green-300 hover:underline"
                  >
                    {pharmacyData.email}
                  </a>
                </p>
                <p className="mb-2 mt-4">
                  <a
                    href={`tel:${pharmacyData.phone}`}
                    className="hover:text-green-300 hover:underline"
                  >
                    {pharmacyData.phone}
                  </a>
                </p>
              </div>
            </div>
            <img
              src={bgCoordonnees}
              className="rounded-3xl"
              alt="pharmacie brisson saint chamas"
            />
          </div>
        </main>
        <News />
      </div>
    );
}

export default Home;