import React from 'react';
import Order from "../Order";

//Affichage de la liste des commandes
function OrderList(props) {

    if(!props.order) {
        return null
    } else if (props.orders.length === 0) {
        return <div className="mx-10">Aucune commande à afficher</div>
    }

    return (
        <div className="mt-5 mx-1 lg:mx-4">
            <ul>
                <li key="en-tete-order" className="flex mb-2 font-bold text-xs md:text-base text-center">
                    <div className="w-1/4">N° de commande</div>
                    <div className="w-1/4">Date</div>
                    <div className="w-1/4">Afficher détails</div>
                    <div className="w-1/4">Statut</div>
                </li>
                <hr/>
                {props.orders.map((order, index) => <Order key={index} order={order}/>)}
            </ul>
        </div>
    );
}

export default OrderList;