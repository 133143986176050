import React from 'react';
import {Slide} from 'react-slideshow-image';

//Le slider - utilisation d'une dependance
const Slideshow = (props) => {

    const fadeProperties = {
        duration: 2000,
        pauseOnHover: true,
        easing : "ease",
        arrows : false,
        indicators : false,
        transitionDuration : 2000
    };

    return (
        <div className="w-3/4 lg:w-1/2 border shadow-md ml-10">
            <Slide {...fadeProperties}>
                {props.urls.map(url =>
                    <div className="each-slide" key={url}>
                        <div className="flex items-center justify-center h-64">
                            <img className="max-h-60" src={url} alt="Promotion en cours pharmacie brisson saint chamas"/>
                        </div>
                    </div>
                )}
            </Slide>
        </div>
    )
};

export default Slideshow;
