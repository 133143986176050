import React from 'react';
import ClickCollectContent from "./ClickCollectContent";
import icon from '../../assets/img/SVG/clic-hand.svg';

function ClickCollect() {


    //Affichage de la bannière au click
    const handleClick = () => {

        const banner = document.getElementById("banner");
        banner.classList.contains("hidden") ? banner.classList.remove('hidden') : banner.classList.add('hidden', 'animate__animated', 'animate__fadeInLeft')
    }


    //Si on est sur la page d'accueil on affiche le composant
    if (window.location.pathname === '/') {
        return <ClickCollectContent/>
    }


    //Sinon, on le cache et il faut cliquer pour l'afficher
    return (
        <>
            <button onClick={handleClick} className="hidden lg:flex flex-col items-center absolute left-0 border-2 rounded bg-green-one bg-opacity-30 hover:bg-white p-2 mt-10 hover:shadow-lg" title="Afficher le bandeau">
                <img src={icon} loading="lazy" alt="Click&Collect pharmacie brisson saint chamas"/>
                <p className="text-gray-400 text-xs mt-2">Click&Collect</p>
            </button>
            <div className="hidden" id="banner">
                <ClickCollectContent/>
            </div>
        </>
    );
}

export default ClickCollect;