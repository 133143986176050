import React from 'react';
import {Link} from "react-router-dom";

import check from '../../../assets/img/SVG/check.svg';

function ScanConfirmation() {

    return (
        <div className="w-full">
            <div className="flex flex-col items-center">
                <img src={check} alt="Confirmation d'envoi" className="w-36 mb-12"/>
                <p className="text-gray-400 text-center">Votre ordonnance a bien été envoyée à votre pharmacien</p>
            </div>
            <div className="flex flex-col items-center lg:flex-row justify-center my-20">
                <Link to="/mon-compte" className="w-48 mx-4"><button className="btn-black mb-2 lg:m-5">Mon compte</button></Link>
                <Link to="/" className="w-48 mx-4"><button className="btn-black mb-2 lg:m-5">Accueil</button></Link>
                <Link to="/ordonnance/etapes" className="w-48 mx-4"><button className="btn-black mb-2 lg:m-5">Envoyer ordonnance</button></Link>
            </div>
        </div>
    );
}

export default ScanConfirmation;