import React from 'react';
import Navbar from "./Navbar";
import wave from '../../assets/img/SVG/wave.svg';
import waveMobile from '../../assets/img/SVG/wave-mobile.svg';

//Header, l'image à afficher diffère en fonction de la taille de l'écran
function Header() {
    return (
      <header className="relative">
        <img
          src={wave}
          className="hidden sm:block absolute z-20"
          alt="Vague d'en-tête pharmacie brisson st chamas"
        />
        <img
          src={waveMobile}
          className="sm:hidden absolute z-10"
          alt="Vague d'en-tête pharmacie brisson st chamas"
        />
        <div className="fixed z-50 w-full bg-gradient-to-br from-primary to-secondary">
          <Navbar />
        </div>
      </header>
    );
}

export default Header;