import React, {useRef} from 'react';
import clic from "../../../assets/img/SVG/click&co.svg";
import google from "../../../assets/img/SVG/googleplay.svg";
import qrGoogle from "../../../assets/img/qr-code-google.png";
import apple from "../../../assets/img/SVG/applestore.svg";
import qrApple from "../../../assets/img/qr-code-apple.png";
import {slideInLeft} from 'react-animations';
import styled, {keyframes} from "styled-components";


const SlideDiv = styled.div`animation: 1s ${keyframes`${slideInLeft}`}`;

const ClickCollectContent = () => {
const slidediv = useRef();
    return (
      <SlideDiv ref={slidediv}>
        <article className="lg:px-24 py-5 flex flex-col items-center md:flex-row justify-around bg-white rounded-b-2xl border shadow-xl">
          <div className="w-3/4 lg:w-1/2 lg:w-auto flex justify-center">
            <img
              src={clic}
              loading="lazy"
              alt="click & collect pharmacie brisson saint chamas"
              className="h-24 lg:h-32"
            />
          </div>
          <div className="lg:w-1/2 lg:w-auto flex flex-col justify-center mt-6 lg:mt-0 lg:px-24 md:mx-2">
            <h2 className="text-center lg:text-lg text-green-one">
              Téléchargez notre application mobile dès maintenant !
            </h2>
            <div className="flex flex-col items-center md:flex-row justify-center mt-3">
              <div className="flex md:flex-col items-center lg:mx-5">
                <a
                  href="https://play.google.com/store/apps/details?id=com.firebaseappbuild"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="h-14 mr-2 lg:mr-0"
                    src={google}
                    alt="Téléchargez l'application sur Google Play"
                  />
                </a>
                <img
                  src={qrGoogle}
                  loading="lazy"
                  alt="QR-code Google"
                  className="h-16"
                />
              </div>
              <div className="flex md:flex-col items-center lg:mx-5">
                <a
                  href="https://apps.apple.com/fr/app/katalysmyscan/id1553871716"
                  target="_blank"
                  rel="noreferrer"
                  className="mt-2"
                >
                  <img
                    src={apple}
                    className="m-4 md:m-0 h-10"
                    alt="Téléchargez l'application sur l'Apple Store"
                  />
                </a>
                <img
                  src={qrApple}
                  loading="lazy"
                  alt="QR-code Apple"
                  className="h-16 mt-3"
                />
              </div>
            </div>
          </div>
        </article>
      </SlideDiv>
    );
}

export default ClickCollectContent;