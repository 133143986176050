import React, {useContext, useEffect, useState} from 'react';
import FirebaseContext from "../../contexts/FirebaseContext";
import PharmacyContext from "../../contexts/PharmacyContext";
import {getFirebaseErrorMessage} from "../../utilsFunctions";
import Slideshow from "./Slider";
import etiquette from '../../assets/img/SVG/etiquette-promo.svg';

//Bandeau des promos - si pas de promo, le bandeau ne s'affiche pas
function PromotionsSlider() {

    const [promos, setPromos] = useState([]);
    const [error, setError] = useState("");
    const firebase = useContext(FirebaseContext);
    const pharmacy = useContext(PharmacyContext);

    //On récupère la liste des promotions du pharmacien
    useEffect(() => {

        if(pharmacy) {
            firebase.getPromotions(pharmacy.authID)
                .then(res => {
                    setError("");
                    setPromos(res.docs);
                })
                .catch(err => {
                    console.log(err.message);
                    setError("Chargement des promotions : " +getFirebaseErrorMessage(err.code));
                })
        }
    }, [pharmacy]);


    //On récupère les URL des fichiers promos
    const defineData = () => {

        const datas = []

        for(let i = 0; i < promos.length; i++) {
            datas.push(promos[i].data().url);
        }

        return datas;
    }

    //Si pas de promos on affiche rien
    if(promos.length === 0) {
        return <div> </div>
    }


    return (
        <div className="py-4 text-center w-full border rounded-b-2xl border shadow-xl">
            <p className="text-red-600">{error}</p>
            <div className="flex flex-col lg:flex-row items-center w-full">
                <Slideshow urls={defineData()}/>
                <div className="hidden lg:flex justify-start text-lg lg:text-2xl text-green-one text-center mr-20">
                    <img src={etiquette} alt="Découvrez nos offres promotionnelles pharmacie brisson saint chamas"/>
                </div>
            </div>
        </div>
    );
}

export default PromotionsSlider;