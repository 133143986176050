import React, {useContext, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import FirebaseContext from "../../../contexts/FirebaseContext";
import {getFirebaseErrorMessage, useInput} from "../../../utilsFunctions";
import {toast} from "react-toastify";

//Suppression du profil utilisateur
function DeleteProfil(props) {

    const history = useHistory();
    const firebase = useContext(FirebaseContext);
    const [error, setError] = useState("");
    const password = useInput("");
    const prescriptions = props.prescriptions;
    const orders = props.orders;
    const id = props.userData.id;

    const [deliveredPrescriptions, setDeliveredPrescriptions] = useState('');
    const [deliveredOrders, setDeliveredOrders] = useState("");

    //On filtre les ordonnances, on garde uniquement celles qui ont le statut "DELIVERY";
    const filterPrescriptions = () => {
        return prescriptions.filter(prescription => prescription.status === "DELIVERY");
    }

    //On filtre les commandes, on garde uniquement celles qui ont le statut "DELIVERY";
    const filterOrders = () => {
        return orders.filter(order => order.status === "DELIVERY");
    }

    useEffect(() => {
        setDeliveredPrescriptions(filterPrescriptions());
        setDeliveredOrders(filterOrders());
    }, [prescriptions, orders]);


    //Suppression des documents de l'utilisateur, on supprime uniquement les ordonnances/commandes déjà délivrées et les cartes mutuelle/vitale
    //Les autres ordonnances/commandes sont conservées pour que le pharmacien puisse les préparer
    const deleteDocuments = () => {

        const tab = []

        //On ajoute les URL des ordonnances dans un tableau et on suppr les ordonnances délivrées de la BDD
        for(let prescription of deliveredPrescriptions) {
            tab.push(prescription.image);
            firebase.deletePrescriptions(prescription.id);
        }

        //On récupère les fichiers de l'user dans le storage - mutuelle, carte vitale et ordonnances
        firebase.getFileInStorage(`photos/${id}`)
            .then(resp => {

                //Si l'user a des fichiers dans le storage
                if(resp.items.length > 0) {

                    for(let item of resp.items) {

                        if(item.name.includes('mutuelle')) {
                            firebase.deleteInStorage(item.fullPath);                //Si le fichier est une mutuelle, on suppr
                        } else if (item.name.includes('securite-sociale')) {
                            firebase.deleteInStorage(item.fullPath);                //Si le fichier est une carte vitale, on suppr
                        } else {

                            //On récupère l'URL de l'ordonnance
                            firebase.getURL(item.fullPath)
                                .then(res => {

                                    //On compare l'URL de l'ordonnance avec le tableau des ordonnances délivrées, si l'URL dans le tableau, on suppr du storage
                                    if(tab.indexOf(res) !== -1) {
                                        firebase.deleteInStorage(item.fullPath);
                                    }
                                })
                                .catch(error => {
                                    console.log("Erreur : " +error.message);
                                })
                        }
                    }
                }
            })
    }

    const deleteOrders = () => {

        //Suppr en BDD
        for(let order of deliveredOrders) {
            firebase.deleteOrders(order.id);
        }
    }

    const deleteAvatar = () => {

        //Suppr en DB
        firebase.deleteAvatar(id);

        //Suppr en storage
        //On récupère l'avatar de l'user dans le storage -
        firebase.getFileInStorage(`avatars/${id}`)
            .then(resp => {
                //Si l'user a un avatar dans le storage, on suppr
                if(resp.items.length > 0) {

                    for(let item of resp.items) {
                        firebase.deleteInStorage(item.fullPath);
                    }
                }
            })
            .catch(err => {
                console.log(err.message);
            })
    }


    //Validation de la suppression du profil
    const handleDelete = async e => {

        e.preventDefault();

        setError("");

        if(!password.value) {
            setError("Veuillez saisir votre mot de passe");
            return;
        }

        const data = {
            email : props.userData.email,
            password : password.value,
        }

        try {
            //On réauthentifie
            await firebase.reauthentificate(data)

            //On suppr les doc storage + bdd
            await deleteDocuments();
            await deleteOrders();
            await deleteAvatar();

            //On suppr le user en bdd + auth et on déco
            await firebase.deleteUserInDB(id)
            await firebase.deleteUser();
            await firebase.signOutUser();

            history.push("/");
            toast("Votre compte a bien été supprimé");

        } catch (err) {
            setError(getFirebaseErrorMessage(err.code));
        }
    }

    const displayUpdateProfil = () => {
        password.empty();
        setError('');
        props.displayUpdateProfil();
    }

    return (

        <div id="delete-profil" className="hidden text-xs sm:text-base flex-col lg:w-1/2 border shadow-lg rounded-xl py-10 px-5 bg-white">
            <p className="text-red-600">{error}</p>
            <p className="mb-10 text-sm md:text-lg font-bold text-purple-one md:ml-4">Êtes-vous sûr de vouloir supprimer votre profil ?</p>
            <div className="flex flex-col md:flex-row">
                <p className="text-purple-one mb-1 md:ml-4">Veuillez saisir votre mot de passe :</p>
                <input className="input-profil md:ml-2" type="password" value={password.value} onChange={password.onChange}/>
            </div>
            <div className="flex flex-col md:flex-row mt-10">
                <button className="btn-black text-xs mb-1 md:mb-0 md:mx-4" onClick={handleDelete}>Valider</button>
                <button className="btn-black text-xs md:mx-4" onClick={displayUpdateProfil}>Annuler</button>
            </div>
        </div>
    );
}

export default DeleteProfil;