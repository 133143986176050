import React from 'react';
import Prescription from "../Prescription";

//Affichage de la liste des ordonnances
function PrescriptionList(props) {

    if(!props.prescription) {
        return null
    } else if (props.prescriptions.length === 0) {
        return <div className="mx-10">Aucune ordonnance à afficher</div>
    }

    return (
        <div className="mt-5 mx-1 lg:mx-4">
            <ul>
                <li key="X" className="flex mb-2 font-bold text-xs md:text-base text-center">
                    <div className="w-1/4 lg:w-1/5">N° d'ordonnance</div>
                    <div className="w-1/4 lg:w-1/5">Date d'envoi</div>
                    <div className="w-1/4 lg:w-1/5">Afficher détails</div>
                    <div className="w-1/4 lg:w-1/5 hidden lg:block">Commentaires</div>
                    <div className="w-1/4 lg:w-1/5">Statut</div>
                </li>
                <hr/>
                {props.prescriptions.map((prescription,index) => <Prescription key={index} prescription={prescription}/>)}
            </ul>
        </div>
    );
}

export default PrescriptionList;