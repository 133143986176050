import React from 'react';
import picture from "../../assets/img/home.jpeg"

//Image de fond et titre principal "Pharmacie Brisson"
function HomeTitle() {

    return (

        <div className="relative">
            <div className="absolute z-10 flex justify-center items-center h-full w-full">
                <h1 className="text-center bg-white bg-opacity-75 text-2xl sm:text-4xl md:text-5xl lg:text-7xl text-green-one rounded-3xl px-3 sm:px-10 lg:px-20 py-5 lg:py-10"><strong>Pharmacie Brisson</strong></h1>
            </div>
            <img src={picture} loading="lazy" alt="Pharmacien cherchant des médicaments pharmacie brisson saint chamas" className="top-0 z-0"/>
        </div>
    );
}

export default HomeTitle;