import React from 'react';

import Title from "../../Title";
import Instagram from "./instagram";

import smartphone from '../../../assets/img/SVG/smartphone.svg';

//Affichage des réseaux sociaux + smartphone
function News() {

    return (
        <section className="text-center">
            <Title title={"Nos actualités"}/>
            <div className="flex flex-col lg:flex-row lg:justify-around lg:items-start mx-10 lg:mt-20">
                <div className="lg:w-1/3 lg:mx-2 flex justify-center items-center mb-10 lg:mb-0">
                    <iframe
                        title="facebook feed"
                        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FPharmacieBrissonProvence%2F&tabs=timeline&width=340&height=700&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=196746778926399"
                        width="340" height="620" style={{border: 'none', overflow: 'hidden'}} scrolling="no" frameBorder="0"
                        allowFullScreen={true}
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                </div>
                <div className="self-center lg:w-1/3 lg:mx-2 flex flex-col justify-center items-center mb-10 lg:mb-0">
                    <img className="mb-5 h-96" src={smartphone} alt="Smartphone publicité pour l'application mobile KatalysMyScan pharmacie brisson saint chamas"/>
                    <p className="text-gray-400 text-sm">Téléchargez l'application pour profiter de l'ensemble des services de votre pharmacie !</p>
                </div>
                <div className="lg:w-1/3 lg:mx-2 flex flex-col justify-center items-center justify-around mb-10 lg:mb-0">
                    <Instagram/>
                </div>
            </div>
        </section>
    );
}

export default News;