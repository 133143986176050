import React, {useContext, useEffect, useState} from 'react';
import AuthContext from "../../contexts/AuthContext";
import FirebaseContext from "../../contexts/FirebaseContext";
import ModalAvatar from "./ModalAvatar";

const Avatar = () =>{

    const firebase = useContext(FirebaseContext);
    const userConnected = useContext(AuthContext);
    const id = userConnected.uid;
    const [srcAvatar, setSrcAvatar] = useState("");

    //Affichage du modal
    const [show, setShow] = useState(false);

    const isShowing = () => {
        setShow(!show);
    }

    //On récupère l'url de l'avatar
   

    useEffect(() => {
        const getSrcAvatar = () => {
            firebase.getAvatar(id)
                .then(doc => {
                    if (doc.exists) {
                        let datas = doc.data();
                        setSrcAvatar(datas.avatar);
                    } else {
                        setSrcAvatar("https://firebasestorage.googleapis.com/v0/b/katalys-my-scan-bdd.appspot.com/o/avatars%2Fdefault%2FPikPng.com_avatar-png_368328.png?alt=media&token=ef3957ad-2a53-4979-aaf4-d3ad2e9dfe29");
                    }

                });
        }
        getSrcAvatar();
    }, []);

    return (

        <div className="flex justify-center m-5 ">
            <img alt="mon-avatar" src={srcAvatar} className="hover:opacity-50 w-16 h-16 cursor-pointer hover:bg-gray-300 rounded-full" onClick={isShowing}/>
            <ModalAvatar show={show} isShowing={isShowing} avatar={srcAvatar} userID={id}/>
        </div>
    );
}

export default Avatar;