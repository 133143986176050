import React, {useContext, useEffect, useState} from 'react';
import {getFirebaseErrorMessage, useInput} from "../../../utilsFunctions";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";

import FirebaseContext from "../../../contexts/FirebaseContext";
import AuthContext from "../../../contexts/AuthContext";


//Modification du profil
function UpdateProfil(props) {

    //Initialisation
    const firebase = useContext(FirebaseContext);
    const userConnected = useContext(AuthContext);
    const [error, setError] = useState("");
    const name = useInput("");
    const email = useInput("");
    const phone = useInput("");
    const oldPassword = useInput("");
    const newPassword = useInput("");
    const confirmPassword = useInput("");
    const history = useHistory();

    //Set les valeurs de départ des champs
    useEffect(() => {
        email.setData(userConnected.email);
        name.setData(userConnected.displayName);
        phone.setData(props.userData.phone);
    }, [userConnected, props])


    //Verification du format du téléphone
    const handlePhone = () => {

        const phone = document.getElementById('phone-up');
        phone.validity.patternMismatch ? phone.setCustomValidity("Le format du téléphone doit être 02XXXXXXXX ou +3306XXXXXXXX par ex") : phone.setCustomValidity("");
    }

    //Vérification du format du nom
    const handleName = () => {

        const name = document.getElementById('name-up');
        name.validity.patternMismatch ? name.setCustomValidity("Les chiffres et caractères spéciaux ne sont pas autorisés") : name.setCustomValidity("");
    }

    //Vérification du format du MDP
    const handlePassword = () => {

        const password = document.getElementById('password-up');
        password.validity.patternMismatch ? password.setCustomValidity("Le mot de passe ne respecte pas les critères de sécurité") : password.setCustomValidity("");
    }

    //Validation du formulaire
    const handleSubmit = async e => {

        e.preventDefault();
        setError("");

        //Si le champ "mot de passe actuel" est vide, on stoppe
        if(!oldPassword.value) {
            setError("Veuillez renseigner votre mot de passe svp");
            return;
        }

        //Si le nouveau MDP et la confirmation ne correspondent pas, on stoppe
        if(newPassword.value !== confirmPassword.value) {
            setError("Le nouveau mot de passe et la confirmation ne correspondent pas");
            return;
        }

        //On set les data pour la réauthentification
        const data = {
            email : userConnected.email,
            password : oldPassword.value,
        }

        try {

            //Avant toute modification on réauthentifie l'user
            await firebase.reauthentificate(data);

            //On met à jour l'email dans Firebase Auth
            if(email.value !== userConnected.email) {
                await firebase.updateEmail(email.value);
            }

            //On met à jour le nom dans Firebase Auth
            if(name.value !== userConnected.displayName) {
                await firebase.updateProfil({displayName : name.value});
            }

            //On met à jour le MDP dans Firebase Auth
            if(newPassword.value && newPassword.value === confirmPassword.value) {
               await firebase.updatePassword(newPassword.value)
            }

            //On met à jour l'user en BDD
            await firebase.updateUser(userConnected.uid, {email: email.value, fullName: name.value, phone: phone.value}); //puis en BDD

            history.push("/");
            toast("Vos modifications ont bien été effectuées")

        } catch (error) {
            setError(getFirebaseErrorMessage(error.code));
            //Si échec de l'envoi du formulaire, on vide les champs des MDP
            oldPassword.setData("");
            newPassword.setData("");
            confirmPassword.setData("");
        }
    }

    //Si on annule, on réaffiche les infos users
    const displayUpdateProfil = () => {
        setError("");
        props.displayUpdateProfil();
    }

    return (
        <div className="flex">
            <section id="update-info-user" className="hidden text-xs sm:text-base flex-col lg:w-1/3 border shadow-lg rounded-xl py-10 px-5 bg-white">
                <form>
                    <div className="text-red-600 mb-3">
                        {error}
                    </div>
                    <div className="flex mb-2">
                        <label className="mr-2 text-purple-one" htmlFor="name-up">Nom prénom : </label>
                        <input className="input-profil" id="name-up" type="text" value={name.value} onChange={name.onChange} onKeyUp={handleName}/>
                    </div>
                    <div className="flex mb-2">
                        <label className="mr-2 text-purple-one" htmlFor="phone-up">Téléphone : </label>
                        <input className="input-profil" id="phone-up" type="text" value={phone.value  || ''} onChange={phone.onChange} onKeyUp={handlePhone}/>
                    </div>
                    <div className="flex mb-2">
                        <label className="mr-2 text-purple-one" htmlFor="email-up">Email : </label>
                        <input className="input-profil" id="email-up" name="email" type="email" value={email.value} onChange={email.onChange}/>
                    </div>
                    <div className="mt-2">
                        <div className="flex mb-2 tooltip">
                            <label className="mr-2 text-purple-one" htmlFor="password-up">Nouveau mot de passe : </label>
                            <input className="input-profil" type="password" id="password-up" value={newPassword.value} onChange={newPassword.onChange} onKeyUp={handlePassword}/>
                            <span className="text-xs text-gray-400 tooltiptext">Entre 8 et 20 caractères avec au min. 1 lettre minuscule, 1 lettre majuscule, 1 chiffre, 1 caractère spécial parmi ($ @ % * + - _ !)</span>
                        </div>
                        <div className="flex mb-2">
                            <label className="mr-2 text-purple-one" htmlFor="confirmPassword">Répéter mot de passe : </label>
                            <input className="input-profil" id="confirmPassword" type="password" value={confirmPassword.value} onChange={confirmPassword.onChange}/>
                        </div>
                    </div>
                    <div className="flex mb-2">
                        <label  className="mr-2 text-purple-one" htmlFor="password-old">Mot de passe actuel : </label>
                        <input className="input-profil" id="password-old" type="password" value={oldPassword.value} onChange={oldPassword.onChange} placeholder="obligatoire" required/>
                    </div>
                </form>
                    <div className="flex flex-col md:flex-row mt-10">
                        <button className="btn-black text-xs mb-1 md:mb-0 md:mx-4" onClick={handleSubmit} type="submit">Sauvegarder</button>
                        <button className="btn-black text-xs md:mx-4" onClick={displayUpdateProfil}>Annuler</button>
                    </div>
            </section>
        </div>
    );
}

export default UpdateProfil;