import React, {useState} from 'react';
import {conversion, displayStatus} from "../../../utilsFunctions";
import OrderModal from "../OrderModal";

//Affichage d'une commande
function Order(order) {

    //Affichage du modal de détails de la commande
    const [show, setShow] = useState(false);

    const isShowing = () => {
        setShow(!show);
    }

    return (
        <>
            <li key={order.order.id} className="flex items-center break-words text-xs md:text-base mb-1">
                <div className="w-1/4 px-1">{order.order.id}</div>
                <div className="w-1/4 px-1 text-center">{conversion(order.order.createdAt.seconds)}</div>
                <div className="w-1/4 px-1 text-center"><span className="cursor-pointer hover:text-green-one" onClick={isShowing}>Détails</span></div>
                <div className="w-1/4 px-1 text-center">{displayStatus(order.order.status)}</div>
            </li>
            <hr/>
            <OrderModal show={show} date={conversion(order.order.createdAt.seconds)} isShowing={isShowing} order={order}/>
        </>
    );
}

export default Order;