import React, {useContext, useEffect, useState} from 'react';

import AuthContext from "../../../contexts/AuthContext";
import FirebaseContext from "../../../contexts/FirebaseContext";

import UpdateProfil from "../UpdateProfil";
import DeleteProfil from "../DeleteProfil";

function UserInfos(props) {

    const userConnected = useContext(AuthContext);
    const firebase = useContext(FirebaseContext);
    const [userData, setUserData] = useState("");
    const [error, setError] = useState("");

    //On récupére l'user en BDD car on souhaite avoir le numéro de tel (les autres infos étaient dispo via le userConnected)
    useEffect(() => {

        firebase.getUser(userConnected.uid)
            .then(res => {
                setUserData(res.data());
            })
            .catch(() => {
                setError("Impossible de récupérer certaines données utilisateur");
            })

    }, [userConnected]);


    //Affichage de la modification du profil
    const displayUpdateProfil = () => {

        setError("");
        const updateForm = document.getElementById("update-info-user");
        const infos = document.getElementById("info-user");
        infos.classList.contains("hidden") ? infos.classList.remove("hidden") : infos.classList.add("hidden");
        updateForm.classList.contains("hidden") ? updateForm.classList.remove("hidden") : updateForm.classList.add("hidden");

    }

    //Affichage de la suppression du profil
    const deleteProfil = () => {

        const deleteProfil = document.getElementById("delete-profil");
        const infos = document.getElementById("info-user");
        infos.classList.contains("hidden") ? infos.classList.remove("hidden") : infos.classList.add("hidden");
        deleteProfil.classList.contains("hidden") ? deleteProfil.classList.remove("hidden") : deleteProfil.classList.add("hidden");
    }

    if(!props.userinfos) {
        return null
    }

    return (

        <div className="mx-10">
            <section id="info-user" className="text-xs sm:text-base flex-col lg:w-1/3 border shadow-lg rounded-xl py-10 px-5 bg-white">
                <p className="text-red-600">{error}</p>
                <div className="flex mb-2">
                    <p className="mr-2 text-purple-one">Prénom Nom : </p>
                    <p className="text-gray-400">{userConnected.displayName}</p>
                    <hr/>
                </div>
                <div className="flex mb-2">
                    <p className="mr-2 text-purple-one">Téléphone : </p>
                    <p className="text-gray-400">{userData.phone}</p>
                </div>
                <div className="flex mb-2">
                    <div className="mr-2 text-purple-one">Email : </div>
                    <div className="text-gray-400">{userConnected.email}</div>
                </div>
                <div className="flex">
                    <div className="mr-2 text-purple-one">Mot de passe : </div>
                    <div className="text-gray-400">******</div>
                </div>
                <div className="flex flex-col md:flex-row mt-10">
                    <button className="btn-black text-xs mb-1 md:mb-0 md:mx-4" onClick={displayUpdateProfil}>Modifier le profil</button>
                    <button className="btn-black text-xs md:mx-4" onClick={deleteProfil}>Supprimer le profil</button>
                </div>
            </section>
            <UpdateProfil displayUpdateProfil={displayUpdateProfil} userData={userData}/>
            <DeleteProfil displayUpdateProfil={deleteProfil} prescriptions={props.prescriptions} orders={props.orders} userData={userData}/>
        </div>
    );
}

export default UserInfos;