import React from 'react';
import {conversion, displayStatus} from "../../../utilsFunctions";

//Affichage d'une ordonnance
function Prescription(prescription) {

    return (
        <>
            <li key={prescription.prescription.id} className="flex items-center break-words text-xs md:text-base mb-1">
                <div className="w-1/4 lg:w-1/5 px-1">{prescription.prescription.id}</div>
                <div className="w-1/4 lg:w-1/5 px-1 text-center">{conversion(prescription.prescription.createdAt.seconds)}</div>
                <div className="w-1/4 lg:w-1/5 px-1 text-center"><a href={prescription.prescription.image} rel="noreferrer" target="_blank">Détails</a></div>
                <div className="w-1/4 lg:w-1/5 px-1 hidden lg:block">{prescription.prescription.comment}</div>
                <div className="w-1/4 lg:w-1/5 px-1 text-center">{displayStatus(prescription.prescription.status)}</div>
            </li>
            <hr/>
        </>
    );
}

export default Prescription;