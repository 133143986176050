import app from "firebase/app";
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import firebase from "firebase/app";

const config = {
    apiKey: "AIzaSyCl2KtQWUlccgLAmvxFfWyjI5xnzkTcdGo",
    authDomain: "katalys-my-scan-bdd.firebaseapp.com",
    databaseURL: "https://katalys-my-scan-bdd.firebaseio.com",
    projectId: "katalys-my-scan-bdd",
    storageBucket: "katalys-my-scan-bdd.appspot.com",
    messagingSenderId: "662454511942",
    appId: "1:662454511942:web:32b3e018c14ac3f518dd47",
    measurementId: "G-7ML7N0RT93"
};


//Encapsuler les fonctionnalités Firebase qu'on va utiliser plus tard - un nouvel objet (new Firebase) est instancié dans un contexte

class Firebase {

    constructor() {
        app.initializeApp(config);
        this.auth = app.auth();
        this.db = app.firestore();
        this.storage = app.storage();
    }

    //------------------ Méthodes d'authentification --------------------------

    //Inscription
    signUpUser = (email, password) => {
        return this.auth.createUserWithEmailAndPassword(email, password)
    }

    //Connexion
    signInUser = (email, password) => {
        return this.auth.signInWithEmailAndPassword(email, password);
    }

    //Déconnexion
    signOutUser = () => {
        return this.auth.signOut();
    }

    //Mot de passe oublié
    resetPassword = email => {
        return this.auth.sendPasswordResetEmail(email);
    }

    //Persistence de l'authentification seulement en session
    setSession = () => {
        return this.auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
    }

    //Récupération de l'user actuellement connecté
    getUserConnected = listener => {
        return this.auth.onAuthStateChanged(listener);
    }


    //Réauthentification avant update MDP/email
    reauthentificate = data => {
        let credential = firebase.auth.EmailAuthProvider.credential(data.email, data.password);
        return this.auth.currentUser.reauthenticateWithCredential(credential);
    }

    //Suppression de l'user
    deleteUser = () => {
        return this.auth.currentUser.delete();
    }

    //--------------------- Méthodes de mise à jour de l'user authentifié

    //Modifier le profil de l'user authentifié
    updateProfil = data =>  {
        return this.auth.currentUser.updateProfile(data);
    }

    //Modifier l'email de l'user authentifié
    updateEmail = newEmail => {
        return this.auth.currentUser.updateEmail(newEmail);
    }

    //Modifier le mot de passe de l'user authentifié
    updatePassword = newPassword => {
        return this.auth.currentUser.updatePassword(newPassword);
    }

    //------------------ Méthodes de persistence en BDD ------------------------

    //Récupérer les infos de la pharmacie
    getPharmacy = id => {
        return this.db.collection('members').doc(id).get();
    }

    //Créer un user en BDD avec pour id, l'id de l'user créé par l'inscription
    addUser = (id, data) => {
        return this.db.collection('users').doc(id).set(data);
    }

    //Récupérer l'user en BDD
    getUser = id => {
        return this.db.collection('users').doc(id).get();
    }

    //Modifier l'user en BDD à partir de son id
    updateUser = (id, data) => {
        return this.db.collection('users').doc(id).update(data);
    }

    //Supprimer l'user en BDD
    deleteUserInDB = id => {
        return this.db.collection('users').doc(id).delete();
    }

    //Ajouter un avatar avec pour id, l'id de l'user connecté
    addAvatar = (id, data) => {
        return this.db.collection('avatars').doc(id).set(data);
    }

    //Récupérer l'avatar
    getAvatar = id => {
        return this.db.collection('avatars').doc(id).get();
    }

    //Supprimer l'avatar
    deleteAvatar = id => {
        return this.db.collection('avatars').doc(id).delete();
    }

    //Modifier l'avatar
    updateAvatar = (id, data) => {
        return this.db.collection('avatars').doc(id).update(data);
    }

    //Récupérer les promotions du pharmacien
    getPromotions = id => {
        return this.db.collection('promotions').where("authID", "==", id).orderBy("createdAt", "desc").get();
    }

    //Créer une ordonnance en DB - id générer par Firestore
    addPrescription = data => {
        return this.db.collection('ordonnances').add(data);
    }

    //Récupérer les ordonnances de l'user connecté
    getPrescriptions = id => {
        return this.db.collection('ordonnances').where("authorID", "==", id).orderBy("createdAt", "desc").get();
    }

    //Supprimer les ordonnances délivrées de l'user connecté
    deletePrescriptions = id => {
        return this.db.collection('ordonnances').doc(id).delete();
    }

    //Récupérer les commandes de l'user connecté
    getOrders = id => {
        return this.db.collection('orders').where("authorID", "==", id).orderBy("createdAt", "desc").get();
    }

    //Supprimer les commandes délivrées de l'user connecté
    deleteOrders = id => {
        return this.db.collection('orders').doc(id).delete();
    }

    //------------------ Méthodes liées au storage ------------------------

    //Ajouter un fichier au cloud storage
    addFileInStorage = (path, file) => {
        return this.storage.ref().child(path).put(file);
    }

    //Récupérer les fichiers dans un dossier du storage
    getFileInStorage = path => {
        return this.storage.ref().child(path).listAll();
    }

    //Supprimer un fichier du storage
    deleteInStorage = path => {
        return this.storage.ref().child(path).delete();
    }

    //Récupérer l'URL du fichier envoyé dans le storage
    getURL = path => {
        return this.storage.ref().child(path).getDownloadURL();
    }

}

export default Firebase;



/*

CECI EST LA CONFIG DE TEST

const config = {
    apiKey: "AIzaSyBaL9AGTWi8BQDjALkVMip-U5upuBXDYGk",
    authDomain: "pharmacie-brisson-test.firebaseapp.com",
    projectId: "pharmacie-brisson-test",
    storageBucket: "pharmacie-brisson-test.appspot.com",
    messagingSenderId: "62027167750",
    appId: "1:62027167750:web:b88c45d2b2a78e6bfceb87",
    measurementId: "G-G4YZLZHW2T"
};
app.initializeApp(config);
 */