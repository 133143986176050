import React, {useContext} from 'react';
import {Redirect, Route} from "react-router-dom";

import AuthContext from "../../contexts/AuthContext";

import Loader from "../Loader";

//Définition des routes privées : si page en chargement = affichage d'un loader
//Sinon, si l'user n'est pas connecté on redirige sur l'accueil
function PrivateRoute(props) {

    const isAuth = useContext(AuthContext);

    if(props.isLoading) {
        return <Loader/>
    }

    if(isAuth) {
        return <Route path={props.path} component={props.component} />
    }

    return (
        <Redirect to="/"/>
    );
}

export default PrivateRoute;