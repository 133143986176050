import React, {useContext, useEffect, useState} from 'react';
import {getFirebaseErrorMessage, useInput} from "../../utilsFunctions";
import {Link, useHistory} from 'react-router-dom';
import {toast} from "react-toastify";
import FirebaseContext from "../../contexts/FirebaseContext";
import AuthContext from "../../contexts/AuthContext";
import image from "../../assets/img/SVG/connexion.svg";

//Connexion
const SignIn = (props) => {
console.log(props)
    //Initialisation
    const email = useInput("");
    const password = useInput("");
    const history = useHistory();
    const firebase = useContext(FirebaseContext);
    const [error, setError] = useState("");

    //Si l'utilisateur est connecté et qu'il tente d'accéder à la page, on le renvoit sur la page d'erreur
    const userConnected = useContext(AuthContext);

    useEffect(() => {
        if (userConnected) {
            history.push("/error");
        }
    }, [userConnected, history]);

    //Faire disparaitre le footer sur cette page
    useEffect(() => {

        document.querySelector("footer").classList.add("hidden");

        return () => {
            document.querySelector("footer").classList.remove("hidden");
        }
    }, []);


    //Disabled le bouton tant que tous les champs ne sont pas remplis
    const btn = email.value && password.value ?
        <button className="btn-black mt-6" type="submit">Se connecter</button> : <button className="btn-black cursor-not-allowed mt-6" type="submit" disabled>Se connecter</button>

    //Validation du formulaire de connexion
    const handleSubmit = e => {

        e.preventDefault();

        //On set la session et on initialise la connexion
        firebase.setSession();
        firebase.signInUser(email.value, password.value)
            .then(() => {
                toast("Vous êtes bien connecté !");
                history.push("/mon-compte"); //Pour rediriger sur la page "mon-compte" >>> trouver une autre solution pourrait être intéressant
            })
            .catch(error => {
                setError(getFirebaseErrorMessage(error.code));

                //En cas d'échec de la connexion, on vide les champs du formulaire
                email.empty();
                password.empty();
            })

    }

    //Affichage des erreurs du formulaire d'inscription
    const errorMsg = error && <span className="text-sm mb-10 text-red-600 text-center">{error}</span>

    return (

        <div className="relative flex justify-around mt-10 lg:mt-0 lg:pt-48 z-10">
            <aside className="hidden lg:flex justify-center lg:w-1/2 lg:pl-36">
                <img src={image} alt="Connexion pharmacie brisson saint chamas" className="w-96 h-auto"/>
            </aside>
            <section className="flex flex-col lg:w-1/2 lg:pr-36 mt-8 xl:mt-12 mx-10 lg:mx-0">
                <h1 className="text-center text-3xl text-green-one uppercase mb-5">Connexion</h1>
                <p className="text-center text-sm text-gray-400 mb-10 xl:mb-14 mt-6">Connectez-vous pour accéder à toutes vos commandes et ordonnances</p>
                {errorMsg}

                <form onSubmit={handleSubmit}>
                    <div className="flex flex-col items-center lg:mx-20 ">
                        <div className="w-full">
                            <div className="flex justify-center text-gray-400 focus-within:text-gray-600 mb-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 22 24">
                                    <path id="Tracé_460" d="M25.989,10.842a2.629,2.629,0,0,0-1.034-2.147L15,2,5.045,8.695A2.638,2.638,0,0,0,4,10.842V23.474A2.379,2.379,0,0,0,6.2,26H23.8A2.379,2.379,0,0,0,26,23.474l-.011-12.632ZM15,17.158,5.914,10.634,15,4.526l9.086,6.107Z" transform="translate(-4 -2)" fill="currentColor"/>
                                </svg>
                                <input className="text-center focus:outline-none" type="email" placeholder="Email" value={email.value} onChange={email.onChange}/>
                            </div>
                            <hr className="border-none h-0.5 bg-green-one mb-5"/>
                        </div>
                        <div className="w-full">
                            <div className="flex justify-center text-gray-400 focus-within:text-gray-600 mb-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18.291" height="24.007" viewBox="0 0 18.291 24.007">
                                    <path id="Tracé_463" d="M24,10H22.862V7.716a5.716,5.716,0,1,0-11.432,0V10H10.286A2.286,2.286,0,0,0,8,12.289V23.721a2.286,2.286,0,0,0,2.286,2.286H24a2.286,2.286,0,0,0,2.286-2.286V12.289A2.286,2.286,0,0,0,24,10ZM17.146,20.291A2.286,2.286,0,1,1,19.432,18,2.286,2.286,0,0,1,17.146,20.291ZM20.689,10H13.6V7.716a3.544,3.544,0,1,1,7.088,0Z" transform="translate(-8 -2)" fill="currentColor"/>
                                </svg>
                                <input className="text-center focus:outline-none" type="password" placeholder="Mot de passe" value={password.value} onChange={password.onChange}/>
                            </div>
                            <hr className="border-none h-0.5 bg-green-one mb-5"/>
                        </div>
                        <div className="text-center w-full">
                            {btn}
                        </div>
                        <div className="w-full text-xs md:text-sm text-gray-400 mt-6 mb-10">
                            <hr className="border-none h-0.5 bg-gray-400 mt-6"/>
                            <p className="mt-2 hover:text-green-one"><Link to="/inscription">Pas encore de compte ? Inscrivez-vous</Link></p>
                            <p className="hover:text-green-one"><Link to="/reinitialisation-connexion">Mot de passe oublié ? Réinitialiser</Link></p>
                        </div>
                    </div>
                </form>
            </section>
        </div>
    );
}

export default SignIn;