import React, {Fragment, useContext, useRef, useState} from 'react';
import FirebaseContext from "../../../contexts/FirebaseContext";
import {toast} from "react-toastify";


function ModalAvatar(props) {

    const preview = useRef(null);
    const [error, setError] = useState("");
    const [avatar, setAvatar] = useState("");
    const firebase = useContext(FirebaseContext);

    //On définit le chemin ou l'avatar sera stocké
    const definePath = file => {

        let path = `avatars/${props.userID}/avatar-${Date.now()}`;
        let pathExt;

        switch (file.type) {
            case "image/png" : pathExt = path + ".png"; break;
            case "image/jpeg" :
            default : pathExt = path + ".jpg";
        }
        return pathExt;
    }

    //Validation du formulaire
    const handleSubmit = e => {

        e.preventDefault();
        const img = document.getElementById("preview-avatar");

        //Si pas d'image de selectionnée, on affiche un message d'erreur
        if(!img) {
            setError("Veuillez sélectionner une image avant de valider");
            return;
        }

        if(!error) {

            //On regarde si il existe déjà un fichier pour l'utilisateur (autre que l'avatar par défaut)
            firebase.getFileInStorage(`avatars/${props.userID}`)
                .then(resp => {

                    //Si oui, on récupère le path du fichier et on le suppr du storage (afin d'éviter qu'un utilisateur ne stocke plusieurs avatars et encombre le storage inutilement)
                    if(resp.items.length > 0) {
                        firebase.deleteInStorage(resp.items[0].fullPath)
                    }
                })
                .catch(error => {
                    console.log("Erreur : " +error.message);
                })


            //On ajoute au storage le nouvel avatar avec le path défini
            const pathExt = definePath(avatar);

            firebase.addFileInStorage(pathExt, avatar)
                .then(() => {

                    //On récupère l'URL de l'avatar ajouté au storage pour l'ajouter en DB
                    firebase.getURL(pathExt)
                        .then(url => {

                            //Ajouter l'avatar en DB
                            const data = {
                                authorID : props.userID,
                                avatar : url,
                                createdAt : new Date()
                            }

                            firebase.addAvatar(props.userID, data)
                                .then(() => {
                                    //history.push("/");
                                    toast("Avatar ajouté avec succès");
                                    window.location.href = "/mon-compte";
                                })
                                .catch(error => {
                                    console.log("Erreur :" + error.message);
                                })
                        })

                })
                .catch(error => {
                    console.log("Erreur : " + error.message);
                })

        }

    }


    //Quand un fichier est sélectionné on prévisualise
    const handleChange = () => {

        setError("");

        if(preview.current.hasChildNodes()) {
            handleCancel();
        }

        const input = document.getElementById("input-avatar");
        const file = input.files[0];

        if(file.type === "image/jpeg" || file.type === "image/png") {
            const image = document.createElement('img');
            image.src = URL.createObjectURL(file);
            image.classList.add("w-16", "h-16", "rounded-full");
            image.id = "preview-avatar"
            preview.current.appendChild(image);
            setAvatar(file);

        } else {
            setError("Veuillez choisir un format de fichier valide (png, jpg)");
        }
    }

    //On annule le choix de l'avatar
    const handleCancel = () => {
        const img = document.getElementById("preview-avatar");
        if(preview.current.hasChildNodes()) {
            preview.current.removeChild(img);
        }
        setError("");
    }

    const isShowing = () => {
        setError("");
        props.isShowing();
    }

    if(!props.show) {
        return null
    }

    return (

        <Fragment>
            <div id="modal-overlay" onClick={isShowing} className="fixed inset-0 z-50 overflow-hidden bg-gray-400 bg-opacity-90 w-screen h-screen flex flex-col items-center justify-center">
                <div onClick={event => event.stopPropagation()} className="my-10 py-3 bg-white rounded-lg overflow-auto lg:w-1/2">
                    <div id="modal-header" className="float-right mr-5">
                        <button type="button" className="cursor-pointer text-3xl font-semibold" onClick={isShowing}>&times;</button>
                    </div>
                    <div id="modal-body" className="flex flex-col mt-6 mx-2 lg:mx-10">
                        <p className="text-red-600">{error}</p>
                        <form>
                        <div className="flex justify-around mb-5 w-full">
                            <div className="lg:w-1/2 flex justify-center items-center mr-2">
                                <label htmlFor="input-avatar" className="text-xs border-2 p-2 rounded hover:shadow-lg cursor-pointer">Choisir un fichier</label>
                                <input type="file" id="input-avatar" accept="image/png, image/jpeg" onChange={handleChange} className="hidden"/>
                            </div>
                            <div className="lg:w-1/2 flex justify-center">
                                <div className="mr-1 lg:mr-2">
                                    <button type="submit" className="text-xs btn-black px-3" onClick={handleSubmit}>Valider</button>
                                </div>
                                <div className="">
                                    <button type="reset" className="text-xs btn-black px-3" onClick={handleCancel}>Annuler</button>
                                </div>
                            </div>
                        </div>
                        </form>
                        <div ref={preview} className="flex justify-center self-center rounded-full border w-16 h-16">

                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    );
}

export default ModalAvatar;