import {useState} from "react";

//Hook personnalisé permettant de factoriser du code pour les input des form
export const useInput = (initialValue) => {

    const [value, setValue] = useState(initialValue);

    const handleChange = e => {
        setValue(e.target.value);
    };

    const emptyValue = () => {
        setValue("");
    }

    const setData = data => {
        setValue(data);
    }

    return {value, onChange: handleChange, empty : emptyValue, setData : setData};
}

//Conversion secondes vers date au format XX/XX/XXXX
export const conversion = (seconds) => {
    return new Date(seconds * 1000).toLocaleDateString();
}

//Personnalisation des messages d'err pour l'authentification
export const getFirebaseErrorMessage = (code) => {

    const USER_NOT_FOUND = "Utilisateur non trouvé";
    const EMAIL_ALREADY_EXIST = "Cet email est déjà utilisé";
    const INTERNAL_ERROR = "Erreur interne";
    const INVALID_CREDENTIAL = "Identifiants invalides";
    const INVALID_EMAIL_FORMAT = "Le format de l'email est invalide";
    const INVALID_PASSWORD_FORMAT = "Le format du mot de passe est invalide, 6 caractères minimum requis";
    const WRONG_PASSWORD = "Mot de passe invalide";
    const TOO_MANY_REQUESTS = "Trop de requêtes effectuées, veuillez réessayer ultérieurement";
    const PASSWORD_TOO_SHORT = "Le mot de passe doit faire plus de 6 caractères";
    const EMAIL_ALREADY_IN_USE = "L'email est déjà utilisé";


    let message ;

    switch (code) {
        case "auth/user-not-found":
            message = USER_NOT_FOUND;
            break;
        case "auth/email-already-exists":
            message = EMAIL_ALREADY_EXIST;
            break;
        case "auth/internal-error":
            message = INTERNAL_ERROR;
            break;
        case "auth/invalid-credential":
            message = INVALID_CREDENTIAL;
            break;
        case "auth/invalid-email":
            message = INVALID_EMAIL_FORMAT;
            break;
        case "auth/invalid-password":
            message = INVALID_PASSWORD_FORMAT;
            break;
        case "auth/wrong-password":
            message = WRONG_PASSWORD;
            break;
        case "auth/too-many-requests":
            message = TOO_MANY_REQUESTS;
            break;
        case "auth/weak-password":
            message = PASSWORD_TOO_SHORT;
            break;
        case "auth/email-already-in-use":
            message = EMAIL_ALREADY_IN_USE;
            break;
        default:
            message = "Une erreur inconnue est survenue";
            break;
    }
    return message;
};

//Affichage en français du status des ordonnances et commandes
export const displayStatus = (status) => {

    switch(status) {
        case "SEND": status = "Envoyé";
            break;
        case "INPROGRESS": status = "En cours";
            break;
        case "READY": status = "Prêt";
            break;
        case "DELIVERY": status = "Livrée";
            break;
        case "CANCELLED": status = "Annulée";
            break;
        default: console.log("default status")
    }

    return status;
}
